import React from 'react';

import { IconWrapper } from '../styled-components';

import { ReactComponent as CardUpArrow } from './UpArrow.svg';
// import { ReactComponent as AkerBPDark } from './AkerBP-Logo_H_dark-text.svg';
import styled from "styled-components";





const StyledCardrUpIcon = styled(CardUpArrow)`
  
`
// const StyledAkerBPIconDark = styled(AkerBPDark)`
  
// `

type CardUpArrowIconProps = {
    /**
     * Scale the icon relative to a base size of 2rem
     */
    scale?: number;
    /**
     * An alternative text that is read by a screen reader for accessibility purposes.
     */
    altText: string;
  
};

const CardUpArrowIcon: React.FC<CardUpArrowIconProps> = ({ scale = 1, altText}) => {
    return (
        <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText}>
            <StyledCardrUpIcon/>
        </IconWrapper>
       
        
    );
};

export default CardUpArrowIcon;