import React, { useRef, useEffect } from "react";
import { useSearchParams, useNavigate  } from "react-router-dom";

const LoginRedirect = ({}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(()=>{
        let params = searchParams.get("url")
        if (params){
            navigate(params)
        }
        else{
            navigate('/')
        }
    })

    return (
        <div>
            Redirecting...
        </div>
    );
};

export default LoginRedirect;