import React from 'react';

import { StyledSectionHeader } from './styled-components';

type SectionHeaderProps = {
  /**
   * What to be displayed inside the component.
   */
  children?: string;
  /**
   * Color for the text. This should be either dark og light, default is dark.
   */
  textColor?: string;
};

const SectionHeader: React.FC<SectionHeaderProps> = ({ children, textColor = 'dark' }) => {
  return <StyledSectionHeader>{children}</StyledSectionHeader>;
};

export default SectionHeader;
