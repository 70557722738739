import React, { useEffect, useState, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import {
  useCheckpostcomplete,
  useNavigationToggle,
} from "./hooks/useMenuContext/useMenuContext";
import { lightTheme } from "./styles/colors/light-theme";
import { darkTheme } from "./styles/colors/dark-theme";

import { getSNJSON } from "./api/index";
import { useApiAction } from "./hooks/use-api-action/use-api-action";
import useDarkMode from "./hooks/use-dark-mode";
import Sidebar from "./components/sidebar/SideBar";
import Dashboard from "./pages/dashboard/Dashboard";
import ImageComponent from "./pages/image/Image";
import Header from "./components/header/Header";
import Signage from "./components/signage/Signage";
import LoginRedirect from "./components/login-redirect";

import DgiLogo from "./icons/dgi-logo";
import "./App.css";
import useWindowSize from "./hooks/use-window-size";
import DarkModeSwitch from "./components/header/dark-mode-switch/dark-mode-switch";
import Unauth from "./pages/login/Unauth";
import * as htmlToImage from "html-to-image";
import Snappy from "./components/SnapshotOf/Snappy";

export const MainLayout = styled.div`
  display: grid;
  height: 100vh;
  grid-template-columns: 100%;
  grid-template-rows:
    1fr
    min-content;
  grid-template-areas:
    "content"
    "footer";

  .header-section {
    position: fixed;
    // border-bottom: 1px solid lightgray;
    z-index: 100;
    grid-area: header / header / header / header;
    width: 100%;
    height: 65px;
    display: grid;
    -webkit-box-align: center;
    align-items: center;
  }

  .content-section {
    grid-area: content;
  }

  .footer-section {
    grid-area: footer;
  }
`;

type Props = {
  navigationToggle?: boolean;
  sidebar?: boolean;
};

export const ContentSection = styled.section`
  padding: 0;
  display: grid;
  grid-template-columns: ${(props: Props) =>
      props.navigationToggle ? "18rem" : "5rem"} auto;
  grid-template-areas: ${(props: Props) =>
    props.sidebar ? "'menu content'" : "'content content'"};

  .sidebar {
    grid-area: menu;
  }

  .content {
    grid-area: content;
  }
`;

export const ContentArea = styled.div`
  padding: 2rem;
  display: block;
  visibility: visible;
  transition: margin-left 0.5s;
  background-color: ${({ theme }) => theme.background};
`;

export const MobileContentArea = styled.div`
  margin-top: 65px;
  padding: 0.5rem 0;
  transition: margin-left 0.5s;
  background-color: ${({ theme }) => theme.background};
`;

const App: React.FC = ({}) => {
  // Try to sign in user automatically
  // useMsalAuthentication(InteractionType.Redirect);

  const [fetchJSON, performFetchJSON] = useApiAction(getSNJSON);
  const [OpSJson, setOpSJson] = useState({});
  const [showSidebar, setshowSidebar] = useState(false);
  const [signageUser, setsignageUser] = useState(true);
  const navigationToggle = useNavigationToggle();
  const device = useWindowSize();
  const [theme, toggleTheme]: any = useDarkMode();
  const themeMode = theme === "dark" ? darkTheme : lightTheme;
  const signage_page = window.location.href.indexOf("/Signage") > -1;
  const [snap, setSnap] = useState("");
  const checkpostcomplete = useCheckpostcomplete();
  const a = "Test";

  useEffect(() => {
    if (fetchJSON.data && fetchJSON.data["result"]) {
      setOpSJson(fetchJSON.data["result"]);
    } else {
      let Field = ["Get", "urlHome", "", {}];
      performFetchJSON(Field);
    }
    let wait = 60;
    if (OpSJson && OpSJson["mainPage"] && OpSJson["mainPage"]["refreshRate"]) {
      wait = OpSJson["mainPage"]["refreshRate"];
    }
    const interval = setInterval(() => {
      // if(!document.hidden){
      let Field = ["Get", "urlHome", "", {}];
      performFetchJSON(Field);
      // }
    }, wait * 1000);
    return () => {
      clearInterval(interval);
    };
  }, [fetchJSON.data, OpSJson, checkpostcomplete]);

  // SnapShot Function
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    handleSnap(1000);
    // setSnap('')
  }, [snap]);

  useEffect(() => {
    setSnap("");
  }, [OpSJson]);

  useEffect(() => {
    if (OpSJson && OpSJson["mainPage"]) {
      if (OpSJson["mainPage"]["signage"] == "true") {
        setsignageUser(true);
        setshowSidebar(false);
      } else {
        setsignageUser(false);
        setshowSidebar(true);
      }
    }
  }, [OpSJson]);

  async function handleSnap(time) {
    if (window.location.href.includes("image")) {
      return;
    }
    // setInterval(()=>{
    await new Promise((resolve) => setTimeout(resolve, time));
    htmlToImage
      .toPng(ref.current)
      .then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        setSnap(img.src);
      })
      .catch((error) => {
        console.log(error);
      });
    // }, 1000)
  }

  if (fetchJSON["error"]) {
    return (
      <Router>
        <Routes>
          <Route
            path="/unauth"
            element={
              <Unauth
                light={true}
                theme={fetchJSON}
                massage={fetchJSON["error"]["massage"]}
              />
            }
          />
          <Route path="*" element={<Navigate to="/unauth" />} />
        </Routes>
      </Router>
    );
  } else {
    if (device === "mobile") {
      return (
        <MainLayout>
          <ThemeProvider theme={themeMode}>
            <Header
              theme={theme}
              signage={signage_page}
              toggleTheme={toggleTheme}
              data={OpSJson["mainPage"]}
              icon={
                <a href="/">
                  <DgiLogo scale={1.3} altText="DGI Logo" theme={theme} />
                </a>
              }
            />
            <ContentSection
              navigationToggle={navigationToggle}
              sidebar={false}
              className="content-section"
            >
              <Router>
                <MobileContentArea className="content">
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <Dashboard
                          OpSJson={OpSJson}
                          setshowSidebar={setshowSidebar}
                          theme={theme}
                          toggleTheme={toggleTheme}
                        />
                      }
                    />
                    {!fetchJSON.loading ? (
                      <Route path="*" element={<Navigate to="/" />} />
                    ) : (
                      <Route path="*" element={<p>Loading....</p>} />
                    )}
                    <Route path="/unauth" element={<Navigate to="/" />} />
                  </Routes>
                </MobileContentArea>
              </Router>
            </ContentSection>
          </ThemeProvider>
        </MainLayout>
      );
    } else if (signageUser && snap != "") {
      return <Snappy imageSource={snap} />;
    } else {
      return (
        <MainLayout>
          <ThemeProvider theme={themeMode}>
            {/* <Header
                theme={theme}
                signage={signage_page}
                toggleTheme={toggleTheme}
                data={OpSJson['mainPage']}
                icon={<a href="/"><DgiLogo scale={1.3} altText="DGI Logo"/></a>}
            /> */}
            <ContentSection
              navigationToggle={navigationToggle}
              sidebar={signage_page || showSidebar}
              className="content-section"
            >
              <Router>
                {signage_page ? (
                  ""
                ) : showSidebar ? (
                  <Sidebar
                    className="sidebar"
                    OpSJson={OpSJson}
                    theme={theme}
                  />
                ) : (
                  ""
                )}
                <ContentArea className="content" ref={ref}>
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <Dashboard
                          OpSJson={OpSJson}
                          setshowSidebar={setshowSidebar}
                          theme={theme}
                          toggleTheme={toggleTheme}
                        />
                      }
                    />
                    <Route
                      path="/snap"
                      element={<Snappy imageSource={snap} />}
                    />
                    <Route path="/image" element={<ImageComponent />} />
                    <Route
                      caseSensitive={false}
                      path="/:tabname"
                      element={
                        <Dashboard
                          OpSJson={OpSJson}
                          setshowSidebar={setshowSidebar}
                          theme={theme}
                          toggleTheme={toggleTheme}
                        />
                      }
                    />
                    {/*<Route path='/signage' element={<Signage OpSJson={OpSJson}/>} />*/}
                    <Route
                      caseSensitive={false}
                      path="/login"
                      element={<LoginRedirect />}
                    />
                    {!fetchJSON.loading ? (
                      <Route path="*" element={<Navigate to="/" />} />
                    ) : (
                      <Route path="*" element={<p>Loading....</p>} />
                    )}
                  </Routes>
                </ContentArea>
              </Router>
            </ContentSection>
          </ThemeProvider>
        </MainLayout>
      );
    }
  }
};

export default App;
