import React from 'react';

import { ButtonWrapper, IconWrapper } from './styled-components';
import Text from '../../../../components/text';

type DashboardButtonProps = {
    text?: string,
    icon?: React.ReactNode,
    onClick: Function
};

const DashboardButton: React.FC<DashboardButtonProps> = ({ text, icon, onClick }) => {
    return (
        <ButtonWrapper onClick={() => onClick()}>
            <IconWrapper>{icon}</IconWrapper>
            <Text size="extralarge">{text}</Text>
        </ButtonWrapper>
    );
};

export default DashboardButton;