import React, {useEffect} from 'react';
import {withTheme} from 'styled-components';
import {StyledDonutChart} from "./style-components";

import PropTypes from 'prop-types';

import * as d3 from 'd3';

// TODO DonutChart make it so it doesent rerender when changing from lightmode to darkmode

const DonutChart = ({ id, data, total, centerText, outerRadius, innerRadius, isEmpty, isMobile, showNumber , theme, speedometerlegth }) => {
    useEffect(() => {
        const margin = {
            top: 50, right: 50, bottom: 50, left: 50,
        };
    
        const width = outerRadius + margin.left + margin.right;
        const height = outerRadius + margin.top + margin.bottom;

        let colors = [];

        if(data){
            data.map((d) => {
                colors.push(d.color)
            })
        }

        /*if(isEmpty) {
            colors = [theme.colors.positive];
        } else {
            colors = [theme.colors.negative, theme.colors.attention, theme.colors.neutral];
        }*/

        /** Remove old svg from div before creating new one with updated data. */
        d3.select(`#${id}`)
            .select('svg')
            .remove();

        /** Add svg with given width and height and also append a group tag which would group the svg elements. */
        var svg = d3
            .select(`#${id}`)
            .append('svg')
            .attr('width', width)
            .attr('height', height)
            .append('g')
            .attr('transform', `translate(${width / 2}, ${height / 2})`);
        
        /** Generate the donut diagram with the given inner and outer radius. */    
        var arcGenerator = d3
            .arc()
            .innerRadius(innerRadius)
            .outerRadius(outerRadius);

        /** Generate each part of the donut diagram with the respective data value. */
        var pieGenerator = d3
            .pie()
            .padAngle(0)
            .value((d) => isEmpty ? 1 : d.value);
        
        /** Set a selection of all the parts of the donut diagram. */
        const arc = svg
            .selectAll()
            .data(pieGenerator(data))
            .enter();
          
        /** 
         * Append a new path element to the selection and fill it with the color from the colors array.
         * Add a transition when creating the data.
         */    
        arc.append('path')
            .attr('id', (d) => `${id}-${d.value}`)
            .attr('fill', (d, i) => colors[i])
            .transition()
            .duration(1)
            // .delay(function(d, i) {
            //     return i * 300;
            // })
            .attrTween('d', function(d) {
                var i = d3.interpolate(d.startAngle+0.1, d.endAngle);
                return function(t) {
                    d.endAngle = i(t);
                    return arcGenerator(d);
                }
            });
        
        /** Append the total value inside the donut chart. */
        svg.append('text')
            .attr('dy', isMobile ? '0.3em' : (centerText? '0em' : '0.3em'))
            .style('text-anchor', 'middle')
            .style('font-size', isMobile ? speedometerlegth === 1? '2.5em' : '1.5em' : '2.2em')
            .attr('class', 'donut-chart-inner-number')
            .text(function(d) { return total; });
        
        /** Append the description for the total value inside the donut chart. */    
        svg.append('text')
            .attr('dy', '2.2em')
            .style('text-anchor', 'middle')
            .style('font-size', '0.8em')
            .attr('class', 'donut-chart-inner-text')
            .text(function(d) { return isMobile ? "" : centerText });

        /** Append a text on each part of the donut diagram with the correct value. */    
        arc.append('text')
            .attr('transform', (d) => 'translate(' + arcGenerator.centroid(d) + ')')
            .attr('dy', '0.25em')
            .attr('text-anchor', 'middle')
            .attr('class', 'donut-chart-circle-text')
            .text((d, index) => {
                if (data.length !== 1){
                    return d.value
                }
                return ""
            });
    }, [id, data, total, innerRadius, outerRadius, isMobile,showNumber, isEmpty, theme]);
      
    return <StyledDonutChart id={id} />;
};

DonutChart.propTypes = {
    /** 
     * Unique id for the donut chart
     */
    id: PropTypes.string,
    /**
     * Data set with ChartData for each part of the donut chart
     */
    data: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.number,
            description: PropTypes.string,
            color: PropTypes.string
        })
    ),
    /**
     * Total sum of the donut chart
     */
    total: PropTypes.number,
    /**
     * Inner radius of the donut chart
     */
    innerRadius: PropTypes.number,
    /**
     * Outer radius of the donut chart
     */
    outerRadius: PropTypes.number,
    /**
     * If true the donut chart should display a full circle with fill of the color positive
     */
     isEmpty: PropTypes.bool,
    /**
     * If true the donut chart should have some differences, like text inside will dissappear and numbers only show when there are multiple different data points
     */
    isMobile: PropTypes.bool,
    /**
     * If true the donut chart should have some differences, it means that the display is mobile and the service have incidents of different priority values
     */
    showNumber: PropTypes.bool,
    /* theme */
    theme: PropTypes.object
}

export default withTheme(DonutChart);