import styled from 'styled-components';
import Typography from '../../styles/typography';

type StyledTextProps = {
    color?: string;
    italic: boolean;
    size: string;
    weight: string;
}

export const StyledText = styled.span`
  font-family: ${Typography.text.name};
  color: ${(props: StyledTextProps) => props.color ? props.color : ({ theme }) => theme.mainFont};
  font-style: ${(props: StyledTextProps) => (props.italic ? 'italic' : 'normal')};
  font-size: ${(props: StyledTextProps) => props.size};
  font-weight: ${(props: StyledTextProps) => props.weight};
`;
