import React from 'react'
import {useParams} from 'react-router-dom'
import PageHeader from "../../components/page-header";
import Row from 'react-bootstrap/Row'

import TableTabComponent from '../../components/table/TableTab'
import Kinderegg from '../../components/kinderegg/Kinderegg'
import Speedometer from '../../components/speedometer/speedometer'
import useWindowSize from "../../hooks/use-window-size";
import Graph from "../../components/graph" 
import {useNavigationToggle} from "../../hooks/useMenuContext/useMenuContext";
import {ChartDescriptionWrapper} from "../../components/speedometer/styled-components";
import ChartDescription from "../../components/chart-description";
import FinanceSummary from "../../components/finance-summery-barchart/finance-summary";
import CardComponent from '../../components/card/Card';
import { defaults } from 'react-chartjs-2'
import Typography from "../../styles/typography";
import annotationPlugin from "chartjs-plugin-annotation";
import {Chart} from 'chart.js';
import DarkModeSwitch from '../../components/header/dark-mode-switch/dark-mode-switch';
import Wrapper from '../../components/wrapper/wrapper';
Chart.register(annotationPlugin);
defaults.font.family  = Typography.text.name

type OpSJsonProps = {
	OpSJson: any;
	setshowSidebar: any;
	theme:any;
	toggleTheme:any;
};


const Dashboard: React.FC<OpSJsonProps> = ({OpSJson, setshowSidebar ,theme,toggleTheme}) => {
	const id = useParams();
	const device = useWindowSize();
	
	const navigationToggle = useNavigationToggle();
	var HeadlineAlignment = null
	var HeadlineTitle = null
	if (id.tabname){
		let tab = OpSJson["tabs"] && OpSJson["tabs"].find(el => el.tabName === (id.tabname.replace(/([A-Z])/g, ' $1').trim()))
		if (tab){
			HeadlineAlignment = tab['HeadlineAlignment'] ? tab['HeadlineAlignment'] : 'left' 
			HeadlineTitle = tab['HeadlineTitle']
			if (tab["ShowInSidebar"]){
                setshowSidebar(false)
			}
		}
	}else{
		let tab = OpSJson["tabs"] && OpSJson["tabs"][0]		
		if (tab){
			HeadlineAlignment = tab['HeadlineAlignment'] ? tab['HeadlineAlignment'] : 'left' 
			HeadlineTitle = tab['HeadlineTitle']
			if (tab["ShowInSidebar"]){
                setshowSidebar(false)
			}
		}
	}	

	function showin(el: any){
		if (device === "tablet")
			return el['DisplayinTablet'] === true
		else if (device === "wideDesktop" || device === 'desktop')
			return el['DisplayinWeb'] === true
		else if(device === "mobile") 
			return el['DisplayinMobile'] === true
		
		return false
	}

	if(!OpSJson.hasOwnProperty("tabs")){
		return (
			<p style={{color: theme === "light"? "black" : "white"}}>Loading....</p>
		)
	}
	else {
		if(device === "mobile"){		
			if(OpSJson && OpSJson["tabs"][0] && OpSJson["tabs"][0]["InformationElement"].filter((e) => e["DisplayinMobile"] === true).length > 0){
				return (
					<div style={{textAlign: "center"}}>
						{/* <div style={{display: "flex", justifyContent: "space-around"}}>
						<div>{OpSJson["tabs"][0]['HeadlineTitle']? <h2 style={{ textAlign: "center", color: theme === "light" ? "black" : "white", marginBottom: "10px", border: '1px solid red'}}>{OpSJson["tabs"][0]['HeadlineTitle']}</h2> : ""}</div>
						<div><DarkModeSwitch theme={theme} toggleTheme={toggleTheme} device = {device}/></div>
						</div> */}
					{/* <DarkModeSwitch theme={theme} toggleTheme={toggleTheme} device = {device}/> */}
					{/* {OpSJson["tabs"][0]['HeadlineTitle']? <h2 style={{ textAlign: "center", color: theme === "light" ? "black" : "white", marginBottom: "20px"}}>{OpSJson["tabs"][0]['HeadlineTitle']}</h2> : ""} */}
					
					{/* {OpSJson["tabs"][0]["InformationElement"].filter((e) => e["ElementType"] === "SpeedometerSection" && e["DisplayinMobile"] === true).map((statusItem: any) => {
						return(
							<div style={{textAlign: "center"}}>
								{
									statusItem["HeadingTitle"]?
									<h2 style={{ textAlign: "center", color: theme === "light" ? "black" : "white", marginBottom: "10px"}}>{statusItem["HeadingTitle"]}</h2>
									:
									<h2 style={{color: theme === "light" ? "gray" : "white", padding: '0px' }}></h2>
								}
								{statusItem["HeadingContents"]?  
									<p style={{color: theme === "light" ? "gray" : "white", marginTop: '-2px' }}>{statusItem["HeadingContents"]}</p>
									:
									<p style={{color: theme === "light" ? "gray" : "white", padding: "0px"}}></p>
								}
							</div>
						)
					})} */}

						{/* <div style={{display: "flex", justifyContent: "center"}}>
							<ChartDescriptionWrapper>
								{OpSJson["tabs"][0]["InformationElement"].filter((e) => e["ElementType"] === "SpeedometerSection" && e["DisplayinMobile"] === true)[0]["MasterLegend"].map((item: any, i: number) => {
													return(
														<ChartDescription
															key={`${i}-${item["LegendHeading"]}-description`}
															description={item["LegendHeading"]}
															color={item["LegendColor"]}
														/>
												)})}	
								</ChartDescriptionWrapper>
						</div> */}

						{/* <br /> */}
						
						{OpSJson["tabs"][0]["InformationElement"].filter((e) => e["DisplayinMobile"] === true).map((item, index) => {
							switch(item.ElementType) {
								case "SpeedometerSection":
									return <Speedometer json={item} key={index} noSpace={false} device={device} fromWrapper={false} theme={theme}/>
								case "TableSection":
									return <div style={{marginTop: '8px', marginBottom: '30px'}}>
												<TableTabComponent json={item} key={index} theme={theme} fromWrapper={false}/>
											</div>;
								case "CardSection":
										return <CardComponent json={item} key={index} fromWrapper ={false}/>
								case "Kinderegg":
									return <Kinderegg json={item} key={index} theme={theme} fromWrapper={false}/>
								case "GraphSection":
									return <Graph json={item} key={index} fromKinderegg={false} theme={theme}/>
								case "Wrapper":
									return <Wrapper json={item} key={index} theme={theme} noSpace={false} device={device} fromWrapper={false}/>;
							}
						})}
					</div>
				)
			} else {
				return <span>No data to show</span>
			}
		}
		return (
			<div className="parent-div" style={{ padding: navigationToggle? '0px 1rem': '0px 1rem'}}>
				<div style={{width:"100%",display:"flex"}}>
				<div style={{width:"80%" }}>
				{HeadlineTitle? 
				<PageHeader styling={{textAlign: HeadlineAlignment}}>
					{HeadlineTitle}
				</PageHeader>
				:
				""}
				</div>
				<div style={{width:"20%"}}><DarkModeSwitch  theme={theme} toggleTheme={toggleTheme} />
            </div>
			</div>
				{OpSJson["tabs"].map((tab, index) => {
					if(!id.tabname && index === 0) {
						return tab["InformationElement"].filter(showin).map((item, index) => {
							switch(item.ElementType) {
								case "SpeedometerSection":
									return <Speedometer json={item} key={index} noSpace={false} device={device} fromWrapper={false} theme={theme}/>;
								case "TableSection":
									return <div style={{marginTop: '30px', marginBottom: '30px'}}><TableTabComponent json={item} key={index} theme={theme} fromWrapper={false}/></div>;
								case "CardSection":
                                    return <CardComponent json={item} key={index} fromWrapper ={false}/>
								case "Kinderegg":
									return <Kinderegg json={item} key={index} theme={theme} fromWrapper={false}/>;
								case "FinanceSummaryBar":
									return <div style={{marginTop: '30px', marginBottom: '30px'}}><FinanceSummary json={item} theme={theme} key={index}/></div>;
								case "GraphSection":
									return <Graph json={item} key={index} fromKinderegg={false} theme={theme}/>;
								case "Wrapper":
									return <Wrapper json={item} key={index} theme={theme} noSpace={false} device={device} fromWrapper={false}/>;
								default:
									return ""
							}
						})
					}
					if(tab.tabName.replace(/ /g, '') === id.tabname) {
						return tab["InformationElement"].filter(showin).map((item, index) => {
							switch(item.ElementType) {
								case "SpeedometerSection":
									return <Speedometer json={item} key={index} noSpace={false} device={device} fromWrapper={false} theme={theme}/>;
								case "TableSection":
									return <div style={{marginTop: '30px', marginBottom: '30px'}}><TableTabComponent json={item} key={index} theme={theme} fromWrapper={false}/></div>;
								case "CardSection":
                                    return <CardComponent json={item} key={index} fromWrapper ={false}/>
								case "Kinderegg":
									return <Kinderegg json={item} key={index} theme={theme} fromWrapper={false}/>;
								case "FinanceSummaryBar":
									return <div style={{marginTop: '30px', marginBottom: '30px'}}><FinanceSummary json={item} theme={theme} key={index}/></div>;
								case "GraphSection":
									return <Graph json={item} key={index} fromKinderegg={false} theme={theme}/>
								case "Wrapper":
									return <Wrapper json={item} key={index} theme={theme} noSpace={false} device={device} fromWrapper={false}/>;
								default:
									return ""
							}
						})
					}
				})}
			</div>
		)
	}
}

export default Dashboard