import React from 'react';
import LineChart from "../line-chart";
import QualysBarChart from '../qualys-bar-chart/QualysBarChart';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

type ChartProps = {
    json: any;
    fromKinderegg: boolean;
    theme:any
};

const Graph: React.FC<ChartProps> = ({json,theme, fromKinderegg= false}) => {
  const axiscolour = theme==="light"? 1:0;
    return (
        <Row style={{display: fromKinderegg? 'contents': ''}}>
            {json['GraphItem'] && json['GraphItem'].map((item, index) => {
                switch(item['Data']['Type']) {
                    case "line":    
                        return (
                            fromKinderegg?
                                (<Col style={{justifyContent: 'center'}} key={index}>
                                    <LineChart 
                                        tooltip={item['Data']['Heading']} 
                                        lineData={item["Data"]} 
                                        outsideLegendPosition={item['Data']["legend"]["position"]} 
                                        outsideHeading={item['Data']["Heading"]} 
                                        outsideShowLegend={item['Data']["legend"]["display"]} 
                                        fromKinderegg={fromKinderegg}
                                        axiscolour={axiscolour}
                                    />
                                </Col>)
                                :
                                (<Col style={{display: item['Data']['UseAvailableSpace']? '': 'grid', justifyContent: 'center'}} key={index}>
                                    <LineChart 
                                        tooltip={item['Data']['Heading']} 
                                        lineData={item["Data"]} 
                                        outsideLegendPosition={item['Data']["legend"]["position"]} 
                                        outsideHeading={item['Data']["Heading"]} 
                                        outsideShowLegend={item['Data']["legend"]["display"]} 
                                        fromKinderegg={fromKinderegg}
                                        axiscolour={axiscolour}
                                    />
                                </Col>)
                        )
                    case "bar":
                        return (
                            fromKinderegg?
                                (<Col style={{justifyContent: 'center'}} key={index}>
                                    <QualysBarChart
                                        barData={{labels: item['Data']['labels'], datasets: item['Data']['datasets']}} 
                                        getdata={item}
                                        getmaintainAspectRatio= {false}
                                        limitHeight={true} 
                                        axiscolour={axiscolour}
                                    />
                                </Col>)
                                :
                                (<Col style={{display: item['Data']['UseAvailableSpace']? '': 'grid', justifyContent: 'center'}} key={index}>
                                    <QualysBarChart
                                        barData={{labels: item['Data']['labels'], datasets: item['Data']['datasets']}} 
                                        getdata={item}
                                        getmaintainAspectRatio= {true}
                                        limitHeight={false} 
                                        axiscolour={axiscolour}
                                    />
                                </Col>)
                        )
                    default:
                        return  ""
                }
            })}
        </Row>
    )
}

export default Graph