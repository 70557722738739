import React, {useEffect, useRef, useState} from 'react';

import Colors from '../../../styles/colors';

// import {Hamburger} from '@fast-x/hamburger';
// import '@fast-x/hamburger/dist/hamburger.css';
// import {Chevron} from '@fast-x/chevron';
// import '@fast-x/chevron/dist/chevron.css'
import UserIcon from '../../../icons/user-icon';

import Text from '../../text';

import {CollapseDiv, LogoutButton, UserMenuWrapper, UserNameContainer, TextGray} from './styled-components';
import ContentContainer from '../../content-container';
import DarkModeSwitch from "../dark-mode-switch";
import useWindowSize from "../../../hooks/use-window-size";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import { BsBoxArrowRight } from 'react-icons/bs';

type UserMenuProps = {
  data?: any,
  theme?: string,
  toggleTheme?: () => void,
}

const UserMenu: React.FC<UserMenuProps> = ({data, theme, toggleTheme}) => {
  const contentContainer = useRef<HTMLDivElement>();
  const [menuState, setMenuState] = useState<boolean>(false);
  const device = useWindowSize();
  const { instance } = useMsal();
  // const [isClicked, setIsClicked] = useState<boolean>(false);


  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick)
    }

  }, []);

  const handleButtonClick = () => {
    setMenuState(!menuState);
  };
  // const handleClose = () => {
  //   if(isClicked === false){
  //     setIsClicked(true)
  //   }
  //   else{
  //     setIsClicked(false)
  //   }
    
  // }
  const handleClick = (e: any) => {
    if(!contentContainer.current?.contains(e.target)) {
      setMenuState(false);
    }
  };
  
  const handleLogout = () => {
    instance.logoutRedirect();
  };


  return ( // @ts-ignore
    <div ref={contentContainer}>
        {device === 'mobile'?
            // <Hamburger
            //     className="hamburger-user-menu"
            //     isActive={menuState}
            //     onClick={() => handleButtonClick()}
            //     size="sm"
            // />
            // <></>
            <UserMenuWrapper onClick={() => handleButtonClick()}>
              {/* user icon */}
              {/* <ContentContainer className="icon-container">
                <UserIcon altText="user icon"/>
              </ContentContainer> */}
              <ContentContainer className="user-container">
                <UserNameContainer>
                  <UserIcon altText="user icon" theme={theme}/>
                  {/* <div onClick={() => handleClose()}> */}
                    
                    {/* {isClicked ? <UserIcon altText="user icon" theme={theme}/> : <CloseIcon altText='close icon' scale={1} light theme={theme}/>} */}
                  {/* </div> */}
                  {/* <Text size="large" weight='600' color={Colors.white}>mahadev</Text> */}
                  {/* <Text size="small" weight='400'>{data['companyName'].toUpperCase()}</Text> */}
                </UserNameContainer>
              </ContentContainer>
              <ContentContainer className="arrow-icon-container">
                {/* <Chevron size="1.7em" isActive={menuState} /> */}
              </ContentContainer>
            </UserMenuWrapper>
            :
            <UserMenuWrapper onClick={() => handleButtonClick()}>
              {/* user icon */}
              {/* <ContentContainer className="icon-container">
                <UserIcon altText="user icon"/>
              </ContentContainer> */}
              <ContentContainer className="user-container">
                <UserNameContainer>
                  <Text size="large" weight='600' color={Colors.white}>{data['userName'].toUpperCase()}</Text>
                  <Text size="small" weight='400'>{data['companyName'].toUpperCase()}</Text>
                </UserNameContainer>
              </ContentContainer>
              <ContentContainer className="arrow-icon-container">
                {/* <Chevron size="1.7em" isActive={menuState} /> */}
              </ContentContainer>
            </UserMenuWrapper>}
        {menuState && <CollapseDiv>
            <ContentContainer className="username-container">
                <ContentContainer className="user-text">
                    {/* <TextGray size='large'>User</TextGray> */}
                    <TextGray size='large'>{data['userName']}</TextGray>
                </ContentContainer>
                <ContentContainer className="name-text">
                    {/* <TextGray size='large'>{data['userName']}</TextGray> */}
                </ContentContainer>
            </ContentContainer>
            <ContentContainer className="darkmode-container">
                <ContentContainer className="dark-mode-text">
                    {/* <TextGray size='large'>Dark Mode</TextGray> */}
                    <DarkModeSwitch theme={theme} toggleTheme={toggleTheme} device={device}/>
                </ContentContainer>
                <ContentContainer className="dark-mode-switch">
                  {/* <DarkModeSwitch theme={theme} toggleTheme={toggleTheme} /> */}
                </ContentContainer>
            </ContentContainer>
            <ContentContainer className="logout-button-container">
                <LogoutButton onClick={() => handleLogout()} className="logout-button">
                    <BsBoxArrowRight style={{width: '38px', height: '35px', fill:theme==="light"? "black" : "white"}}/>
                    <TextGray size='large' weight={'700'} style={{marginLeft: "15px"}}>LOG OUT</TextGray>
                </LogoutButton>
            </ContentContainer>
        </CollapseDiv>}
    </div>
  )
};

export default UserMenu;