import React from 'react';

import { IconWrapper } from '../styled-components';

import { ReactComponent as CardDownArrow } from './downArrow.svg';
// import { ReactComponent as AkerBPDark } from './AkerBP-Logo_H_dark-text.svg';
import styled from "styled-components";





const StyledCardrDownIcon = styled(CardDownArrow)`
  
`
// const StyledAkerBPIconDark = styled(AkerBPDark)`
  
// `

type CardDownArrowIconProps = {
    /**
     * Scale the icon relative to a base size of 2rem
     */
    scale?: number;
    /**
     * An alternative text that is read by a screen reader for accessibility purposes.
     */
    altText: string;
  
};

const CardDownArrowIcon: React.FC<CardDownArrowIconProps> = ({ scale = 1, altText}) => {
    return (
        <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText}>
            <StyledCardrDownIcon/>
        </IconWrapper>
       
        
    );
};

export default CardDownArrowIcon;