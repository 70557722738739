import {useEffect, useState} from "react";

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = useState(0);
  const [device, setDevice] = useState('');
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width to state
      setWindowSize(window.innerWidth);
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);

  }, []); // Empty array ensures that effect is only run on mount

  useEffect(() => {
    if(windowSize > 2000) {
      setDevice('wideDesktop');
    }
    if(windowSize > 1200) {
      setDevice('desktop');
    }
    if(windowSize <= 1200) {
      setDevice('tablet')
    } if(windowSize <= 800) {
      setDevice('mobile')
    }
  }, [windowSize]);

  return device;
}

export default useWindowSize;