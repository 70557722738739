import React, {useEffect, useState} from "react";
import {Bar} from "react-chartjs-2";
import {format} from "date-fns";
import { StyledButton, ButtonGrid } from "./styled-components";
import {defaults} from 'react-chartjs-2';
import {Chart} from 'chart.js';
// @ts-ignore
import {firstBy} from "thenby";

type CostBarChartProps = {
  data?: any,
  maxValueData?: any,
  minValueData?: any,
  dataMonth: number,
  dataYear: number,
  light?: boolean
  theme :any
}

const CostBarChart: React.FC<CostBarChartProps> = ({data, light, maxValueData, minValueData, dataMonth, dataYear,theme}) => {
  
  // const blackOrWhite = light ? "black" : "#FFFFFF";
  const blackOrWhite = theme==="light" ? "black" : "#FFFFFF";
  defaults.color = blackOrWhite;
  const WhiteOrBlack = theme=="dark"? 0: 1
  
  const [year, setYear] = useState(dataYear);
  const [month, setMonth] = useState(dataMonth);
  const [areas, setAreas] = useState( []);
  const [cost, setCost] = useState([]);
  const total_cost_year =[]
  data[`${year}`].forEach((e)=>{
    let keys = Object.keys(e);
    keys.forEach(element =>{
      let data = e[element]
      data.forEach(item => {
        total_cost_year.push(item["total_cost"])
      });
    })    
  })
  minValueData =Math.min(...total_cost_year)
  maxValueData =Math.max(...total_cost_year)
  useEffect(() => {
    
    if(data && data[`${year}`] && data[`${year}`][0] && data[`${year}`][0][`${month}`]) {

      const mapping = data[`${year}`][0][`${month}`].sort(
        firstBy(function (v: any) { return v.cost_element; })
      );
      const area = mapping.map(function (e: any) {
        if(e.cost_element === "") {
          return "Ukjent"
        } else {
          return e.cost_element
        }
      });
      const total_cost = mapping.map(function (e: any) {
        return e.total_cost
      });
      
      setAreas(area);
      setCost(total_cost);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, month]);
  
  const handleClickYear = (year: number) => {
    setYear(year)
  };
  
  const handleClickMonth = (month: number) => {
    setMonth(month)
  };

  const barData = {
    labels: areas,
    datasets: [
      {
        data: cost,
        backgroundColor: [
          blackOrWhite,
          blackOrWhite,
          blackOrWhite,
          blackOrWhite,
          blackOrWhite,
          blackOrWhite,
          blackOrWhite,
          blackOrWhite,
          blackOrWhite,
          blackOrWhite,
          blackOrWhite,
          blackOrWhite,
          blackOrWhite,
          blackOrWhite,
          blackOrWhite,
          blackOrWhite,
        ],
        borderWidth: 1,
        barPercentage: 0.6,
      },
    ],
  };
  
  const options = {
    borderRadius: 5,
    indexAxis: 'y',
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    scales: {
      y: {
        ticks: {
          color:WhiteOrBlack?"black":"white"
        },
        grid: {
          drawBorder: false,
          display: false
        }
      },
      x: {
        ticks: {
          color:WhiteOrBlack?"black":"white"
        },
        categorySpacing: 1,
        min: minValueData,
        max: maxValueData,
        grid: {
          drawBorder: false,
          display: false
        }
      },
    },
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    maintainAspectRatio: false,
  };
  
  return (
    <>
      {(data) ?
          <ButtonGrid className="year-button-container">
            {Object.keys(data).map((key, index) => (
                  <StyledButton active={year === parseInt(key)} onClick={() => handleClickYear(parseInt(key))} key={index}>{key}</StyledButton>
            ))}
          </ButtonGrid>
      : <></>}

      {(data && data[`${year}`] && data[`${year}`][0]) ? <ButtonGrid className="month-button-container">
        {Object.keys(data[`${year}`][0]).map((k, index) => (
              <StyledButton  key={index} active={month === parseInt(k)} disabled={data[`${year}`][0][parseInt(k)].length === 0} onClick={() => handleClickMonth(parseInt(k))}>{format(new Date(`${year}-${k}-01 12:00:00`), "MMM")}</StyledButton>
        ))}
      </ButtonGrid> : <></>}
      <div style={{height: '82%'}}>
        {/*@ts-ignore*/}
        <Bar data={barData} options={options}  type='bar'/>
      </div>
    </>
  );
};

export default CostBarChart;