import React, {useEffect, useState} from "react";
import CostBarChart from "./cost-bar-chart/cost-bar-chart";
import {
    FinanceSummaryWrapper,
    FinanceSummaryHeader,
    FinanceSummaryBarChartContainer,
    FSHeaderText
} from "./styled-components";
import {TooltipWrapper} from "./styled-components";
import InfoIcon from "../../icons/info-icon";
import ReactTooltip from "react-tooltip";

type FinanceSummaryProps = {
    json: any
    theme:any
};

const FinanceSummary: React.FC<FinanceSummaryProps> = ({json,theme}: { json: any ;theme:any}) => {

    return (
        <FinanceSummaryWrapper>
            <FinanceSummaryHeader>
                <FSHeaderText>{json.Heading}</FSHeaderText>
                <TooltipWrapper data-tip data-for="it-spend-per-month-graph-tooltip">
                    <InfoIcon altText="Info icon for most recent incidents" isLightTheme={true} />
                    <ReactTooltip id="it-spend-per-month-graph-tooltip" place="top" type={"dark"} effect="solid">
                        Invoice details per month
                    </ReactTooltip>
                </TooltipWrapper>
            </FinanceSummaryHeader>
            <FinanceSummaryBarChartContainer>
                <CostBarChart data={json.data} dataYear={2021} dataMonth={12} light theme={theme} />
            </FinanceSummaryBarChartContainer>
        </FinanceSummaryWrapper>
    );
};

export default FinanceSummary;