import React from 'react'

import useWindowSize from '../../hooks/use-window-size';
import Graph from '../graph'
import TableTapComponent from '../table/TableTab'
import Speedometer from '../speedometer/speedometer'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styled from 'styled-components';

type KindereggProps = {
    json: any;
	theme:any;
	fromWrapper: boolean;
};

export const Head = styled.div`
  background-color: ${({ theme }) => theme.main};
 `;
 
const Kinderegg: React.FC<KindereggProps> = ({json ,theme}) => { 
	
	const device = useWindowSize();
	function showindevice(el: any){
		if (device === "tablet")
			return el['DisplayinTablet'] === true
		else if (device === "wideDesktop" || device === 'desktop')
			return el['DisplayinWeb'] === true
		else if(device === "mobile") 
			return el['DisplayinMobile'] === true
		else
			return false
	}

    return (
		<Head  style={{ padding: '35px', marginRight: '0', marginLeft: '0', marginTop: '30px', marginBottom: '30px', borderRadius: '0.625rem', border: 'none', boxShadow: 'rgb(0 0 0 / 5%) 0px 0px 15px'}}>
			<Row>
            {json['KindereggElement'].filter(showindevice).map((k_item, k_index) => {
                switch(k_item.ElementType) {
					case "SpeedometerSection":
						return k_item['SpeedometerItem'] && k_item['SpeedometerImage'] === ""?
								<Col lg={3} md={12} sm={12}><Speedometer json={k_item} key={k_index} noSpace={true} device={device} fromWrapper={false} theme={theme}/></Col>
								:
								<Col lg={5} md={12} sm={12}><Speedometer json={k_item} key={k_index} noSpace={true} device={device} fromWrapper={false} theme={theme}/></Col>
                    case "GraphSection":
                        return <Graph json={k_item} key={k_index} fromKinderegg={true} theme={theme}/>
                    case "TableSection":
                        return <Col lg={12} md={12} sm={12} xs={12}><TableTapComponent theme={theme} json={k_item} key={k_index} fromWrapper={false}/></Col>
                    default:
                        return <> </>
                }
            })}
			</Row>
			</Head>
    )
}
export default Kinderegg