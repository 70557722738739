import styled from 'styled-components';


type GridLayoutProps = {
  iconIsDisplayed: React.ReactNode
}

export const GridLayout = styled.div`
  display: grid;
  text-align: center;
  align-items: center;

  grid-template-columns: ${(props: GridLayoutProps) => props.iconIsDisplayed ? '10% 1fr auto' : '0rem 1fr auto'};
  grid-template-rows: auto;
  grid-template-areas:
    '. headerText userMenu';

  .header-text-section {
    grid-area: headerText;
  }

  .user-menu-section {
    grid-area: userMenu;

    span {
      color: ${({ theme }) => theme.text.main};
    }

    svg > g > line {
      stroke: ${({ theme }) => theme.text.main};
    }
  }
  
  /*.navigation-section {
    grid-area: navigation;
    display: flex;
    justify-content: space-evenly;
    
    span {
      color: ${({ theme }) => theme.text.main};
    }
    
    svg > g > line {
      stroke: ${({ theme }) => theme.text.main};
    }
  }*/

  color: ${({ theme }) => theme.text.secondary};
  
  @media (max-width: 850px) {
    grid-template-columns:  0.5rem auto auto min-content;
    grid-template-rows: 0.5rem min-content 0.5rem;
    grid-template-areas:
    '. . . userMenu'
    '. headerText . userMenu'
    '. . . userMenu';

    .header-text-section {
      grid-area: headerText;
    }

    .user-menu-section {
      grid-area: userMenu;
    }

    .navigation-section {
      display: none;
    }
  }
  
`;

