import styled from 'styled-components';
import Colors from '../../styles/colors';

export const StatusWrapper = styled.div`
    display: grid;
    margin-top:${(props) => props.fromWrapper=== true? "0px" : '30px'};
    margin-bottom:${(props) => props.fromWrapper=== true? "0px" : '30px'};
    width: ${(props) => props.fromWrapper=== true? "10rem" : '18rem'};
    height: 18rem;
    margin: ${(props) => props.fromWrapper===true? 'auto' : ''};
    grid-template-columns: 10rem 1fr;
    grid-template-areas:
        '. .'
        'header header'
        'chart chart'
        'description description'
        '. .';
    border-radius: ${(props) => props.fromWrapper=== true? "" : '10px'};
    
    .service-chart-header-section {
        grid-area: header;
        text-align: center;
        margin-top: 13px;
        margin-left:${(props) => props.fromWrapper=== true? '-20px' : ''};
    }

    .service-chart-section {
        display: flex;
        justify-content: center;
        grid-area: chart;
        margin-left:${(props) => props.fromWrapper=== true? '-20px' : ''};
        margin-top: ${(props) => props.speedometerlegth === 1 ? "-10px" : "0px"};
    }

    .service-chart-description-section {
        display: flex;
        justify-content: center;
        grid-area: description;
        margin-left:${(props) => props.fromWrapper=== true? '-20px' : ''};
    }
    
    background-color: ${({ theme }) => theme.main};
    color: ${({ theme }) => theme.main.text};
  /*box-shadow: 0px 4px 3px ({ theme }) => theme.light ? Colors.gray : 'none';*/
    text-decoration: none;
    
    // @media (max-width: 640px) {
    //   max-width: 10rem;
    //   height: initial;
    //   justify-content: center;
    // }

    @media (max-width: 800px) {
      margin-top:0px;
      margin-bottom:0px;
      width: ${(props) => props.speedometerlegth === 1 ? '95%' : '50%'};
      height: ${(props) => props.speedometerlegth === 1 ? '17rem' : '11rem'};
      justify-content: center;
      border-radius: 0px;
      .service-chart-header-section{
        margin-top: 0px;
        margin-bottom: ${(props) => props.speedometerlegth === 1 ? "0px" : "-15px"};
      }
    }
    
`;

export const ChartDescriptionWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    width: 10rem; 
`;

export const DescriptionTextWrapper = styled.div`
    display: flex;
    justify-content: center;
    height: 43px;
`;

export const OverviewGridLayout = styled.div`
  display: ${(props) => props.fromWrapper === true ? 'flex' : 'grid'};
  row-gap: 2rem;
  justify-items: center;
  
  @media (max-width: 800px) {
    display: flex;
    flex-wrap: wrap;
    row-gap: normal;
    justify-content: center;
  }
  
`;

export const Heading = styled.p`
color: ${({ theme }) => theme.text.main};
`;