import styled from 'styled-components';
import Colors from '../../styles/colors/colors';
import {SectionContainerProps as Props} from "./section-container";

const backgroundColors = {
  header: Colors.white,
  dark: Colors.darkerGray,
  light: Colors.lighterGray,
  white: Colors.white,
};

const backgroundColorsDark = {
  header: '#17191D',
  dark: '#1D1D1B',
  light: '#313237',
  white: '#4B5158',
};

//const backgroundColorsTablet = Object.create(backgroundColors);
//const backgroundColorsMobile = Object.create(backgroundColors);

const spacing = {
  none: '0',
  lowest: '1rem',
  low: '2.5rem',
  medium: '3rem',
  high: '3.5rem'
};
const spacingTablet = {
  none: '0',
  lowest: '1rem',
  low: '2rem',
  medium: '2.5rem',
  high: '3rem'
};
const spacingMobile = {
  none: '0',
  lowest: '0.5rem',
  low: '1rem',
  medium: '1.5rem',
  high: '2rem'
};

export const StyledSectionContainer = styled.section`
  background-color: ${(props: Props) =>
    props.styling?.backgroundColor && (props.theme?.light ? backgroundColors[props.styling.backgroundColor] : backgroundColorsDark[props.styling.backgroundColor])};

  padding: ${(props: Props) => {
    if (props.styling && props.styling.removeDefaultPadding) {
      return '0';
    } else if (props.styling && props.styling.paddingAllSides) {
      return spacing[props.styling.paddingAllSides];
    } else {
      return ['0', spacing.low].join(' ');
    }
  }};
  padding-top: ${(props: Props) => props.styling?.paddingTop && spacing[props.styling.paddingTop]};
  padding-bottom: ${(props: Props) =>
    props.styling?.paddingBottom && spacing[props.styling.paddingBottom]};
  padding-left: ${(props: Props) =>
    props.styling?.paddingLeft && spacing[props.styling.paddingLeft]};
  padding-right: ${(props: Props) =>
    props.styling?.paddingRight && spacing[props.styling.paddingRight]};

  margin: ${(props: Props) => props.styling?.marginAllSides && spacing[props.styling.marginAllSides]};
  margin-top: ${(props: Props) => props.styling?.marginTop && spacing[props.styling.marginTop]};
  margin-bottom: ${(props: Props) =>
    props.styling?.marginBottom && spacing[props.styling.marginBottom]};
  margin-left: ${(props: Props) => props.styling?.marginLeft && spacing[props.styling.marginLeft]};
  margin-right: ${(props: Props) =>
    props.styling?.marginRight && spacing[props.styling.marginRight]};

  @media (min-width: 1024px) {
    background-color: ${(props: Props) =>
        props.styling?.backgroundColor && (props.theme?.light ? backgroundColors[props.styling.backgroundColor] : backgroundColorsDark[props.styling.backgroundColor])};

    padding: ${(props: Props) =>
      props.styling?.paddingAllSidesDesktopOnly && spacing[props.styling.paddingAllSidesDesktopOnly]};
    padding-top: ${(props: Props) =>
      props.styling?.paddingTopDesktopOnly && spacing[props.styling.paddingTopDesktopOnly]};
    padding-bottom: ${(props: Props) =>
      props.styling?.paddingBottomDesktopOnly && spacing[props.styling.paddingBottomDesktopOnly]};
    padding-left: ${(props: Props) =>
      props.styling?.paddingLeftDesktopOnly && spacing[props.styling.paddingLeftDesktopOnly]};
    padding-right: ${(props: Props) =>
      props.styling?.paddingRightDesktopOnly && spacing[props.styling.paddingRightDesktopOnly]};

    margin: ${(props: Props) =>
      props.styling?.marginAllSidesDesktopOnly && spacing[props.styling.marginAllSidesDesktopOnly]};
    margin-top: ${(props: Props) =>
      props.styling?.marginTopDesktopOnly && spacing[props.styling.marginTopDesktopOnly]};
    margin-bottom: ${(props: Props) =>
      props.styling?.marginBottomDesktopOnly && spacing[props.styling.marginBottomDesktopOnly]};
    margin-left: ${(props: Props) =>
      props.styling?.marginLeftDesktopOnly && spacing[props.styling.marginLeftDesktopOnly]};
    margin-right: ${(props: Props) =>
      props.styling?.marginRightDesktopOnly && spacing[props.styling.marginRightDesktopOnly]};
  }

  @media (min-width: 600px) and (max-width: 1023px) {
    background-color: ${(props: Props) =>
        props.styling?.backgroundColor && (props.theme?.light ? backgroundColors[props.styling.backgroundColor] : backgroundColorsDark[props.styling.backgroundColor])};

    padding: ${(props: Props) => {
      if (props.styling && props.styling.removeDefaultPadding) {
        return '0';
      } else if (props.styling && props.styling.paddingAllSides) {
        return spacingTablet[props.styling.paddingAllSides];
      } else {
        return ['0', spacingTablet.low].join(' ');
      }
    }};

    padding: ${(props: Props) =>
      props.styling?.paddingAllSides && spacingTablet[props.styling.paddingAllSides]};
    padding: ${(props: Props) =>
      props.styling?.paddingAllSidesTabletOnly && spacingTablet[props.styling.paddingAllSidesTabletOnly]};
    padding-top: ${(props: Props) =>
      props.styling?.paddingTop && spacingTablet[props.styling.paddingTop]};
    padding-top: ${(props: Props) =>
      props.styling?.paddingTopTabletOnly && spacingTablet[props.styling.paddingTopTabletOnly]};
    padding-bottom: ${(props: Props) =>
      props.styling?.paddingBottom && spacingTablet[props.styling.paddingBottom]};
    padding-bottom: ${(props: Props) =>
      props.styling?.paddingBottomTabletOnly && spacingTablet[props.styling.paddingBottomTabletOnly]};
    padding-left: ${(props: Props) =>
      props.styling?.paddingLeft && spacingMobile[props.styling.paddingLeft]};
    padding-left: ${(props: Props) =>
      props.styling?.paddingLeftTabletOnly && spacingTablet[props.styling.paddingLeftTabletOnly]};
    padding-right: ${(props: Props) =>
      props.styling?.paddingRight && spacingTablet[props.styling.paddingRight]};
    padding-right: ${(props: Props) =>
      props.styling?.paddingRightTabletOnly && spacingTablet[props.styling.paddingRightTabletOnly]};

    margin: ${(props: Props) =>
      props.styling?.marginAllSides && spacingTablet[props.styling.marginAllSides]};
    margin: ${(props: Props) =>
      props.styling?.marginAllSidesTabletOnly && spacingTablet[props.styling.marginAllSidesTabletOnly]};
    margin-top: ${(props: Props) =>
      props.styling?.marginTop && spacingTablet[props.styling.marginTop]};
    margin-top: ${(props: Props) =>
      props.styling?.marginTopTabletOnly && spacingTablet[props.styling.marginTopTabletOnly]};
    margin-bottom: ${(props: Props) =>
      props.styling?.marginBottom && spacingTablet[props.styling.marginBottom]};
    margin-bottom: ${(props: Props) =>
      props.styling?.marginBottomTabletOnly && spacingTablet[props.styling.marginBottomTabletOnly]};
    margin-left: ${(props: Props) =>
      props.styling?.marginLeft && spacingTablet[props.styling.marginLeft]};
    margin-left: ${(props: Props) =>
      props.styling?.marginLeftTabletOnly && spacingTablet[props.styling.marginLeftTabletOnly]};
    margin-right: ${(props: Props) =>
      props.styling?.marginRight && spacingTablet[props.styling.marginRight]};
    margin-right: ${(props: Props) =>
      props.styling?.marginRightTabletOnly && spacingTablet[props.styling.marginRightTabletOnly]};
  }

  @media (max-width: 599px) {
    background-color: ${(props: Props) =>
        props.styling?.backgroundColor && (props.theme?.light ? backgroundColors[props.styling.backgroundColor] : backgroundColorsDark[props.styling.backgroundColor])};

    padding: ${(props: Props) => {
      if (props.styling && props.styling.removeDefaultPadding) {
        return '0';
      } else if (props.styling && props.styling.paddingAllSides) {
        return spacingMobile[props.styling.paddingAllSides];
      } else {
        return ['0', spacingMobile.low].join(' ');
      }
    }};

    padding: ${(props: Props) =>
      props.styling?.paddingAllSides && spacingMobile[props.styling.paddingAllSides]};
    padding: ${(props: Props) =>
      props.styling?.paddingAllSidesMobileOnly && spacingMobile[props.styling.paddingAllSidesMobileOnly]};
    padding-top: ${(props: Props) =>
      props.styling?.paddingTop && spacingMobile[props.styling.paddingTop]};
    padding-top: ${(props: Props) =>
      props.styling?.paddingTopMobileOnly && spacingMobile[props.styling.paddingTopMobileOnly]};
    padding-bottom: ${(props: Props) =>
      props.styling?.paddingBottom && spacingMobile[props.styling.paddingBottom]};
    padding-bottom: ${(props: Props) =>
      props.styling?.paddingBottomMobileOnly && spacingMobile[props.styling.paddingBottomMobileOnly]};
    padding-left: ${(props: Props) =>
      props.styling?.paddingLeft && spacingMobile[props.styling.paddingLeft]};
    padding-left: ${(props: Props) =>
      props.styling?.paddingLeftMobileOnly && spacingMobile[props.styling.paddingLeftMobileOnly]};
    padding-right: ${(props: Props) =>
      props.styling?.paddingRight && spacingMobile[props.styling.paddingRight]};
    padding-right: ${(props: Props) =>
      props.styling?.paddingRightMobileOnly && spacingMobile[props.styling.paddingRightMobileOnly]};


    margin: ${(props: Props) =>
      props.styling?.marginAllSides && spacingMobile[props.styling.marginAllSides]};
    margin: ${(props: Props) =>
      props.styling?.marginAllSidesMobileOnly && spacingMobile[props.styling.marginAllSidesMobileOnly]};
    margin-top: ${(props: Props) =>
      props.styling?.marginTop && spacingMobile[props.styling.marginTop]};
    margin-top: ${(props: Props) =>
      props.styling?.marginTopMobileOnly && spacingMobile[props.styling.marginTopMobileOnly]};
    margin-bottom: ${(props: Props) =>
      props.styling?.marginBottom && spacingMobile[props.styling.marginBottom]};
    margin-bottom: ${(props: Props) =>
      props.styling?.marginBottomMobileOnly && spacingMobile[props.styling.marginBottomMobileOnly]};
    margin-left: ${(props: Props) =>
      props.styling?.marginLeft && spacingMobile[props.styling.marginLeft]};
    margin-left: ${(props: Props) =>
      props.styling?.marginLeftMobileOnly && spacingMobile[props.styling.marginLeftMobileOnly]};
    margin-right: ${(props: Props) =>
      props.styling?.marginRight && spacingMobile[props.styling.marginRight]};
    margin-right: ${(props: Props) =>
      props.styling?.marginRightMobileOnly && spacingMobile[props.styling.marginRightMobileOnly]};
  }
`;
