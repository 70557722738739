import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {PublicClientApplication} from "@azure/msal-browser";
import {AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate} from "@azure/msal-react";
import reportWebVitals from './reportWebVitals';
import {MenuProvider} from './hooks/useMenuContext/useMenuContext'
import {msalConfig} from "./authentication/authConfig";
import ApplicationRouter from "./authentication/applicationRouter";
import 'bootstrap/dist/css/bootstrap.min.css';

export const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
      <MsalProvider instance={msalInstance}>
          <MenuProvider>
              <ApplicationRouter/>
          </MenuProvider>
      </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
