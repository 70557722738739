import styled from "styled-components";

export const CardItem = styled.div`
    border-radius: 10px;
    box-shadow: ${(props) => props.fromWrapper === true ? "" : '0px 0px 15px rgba(0, 0, 0, 0.05)'};
    font-family:sans-serif;
    background-color: ${({ theme }) => theme.main};
    color: ${({ theme }) => theme.text.main};
    padding:${(props) => props.fromWrapper === true? "20px 19px 20px 10px" : '30px 19px 20px 30px'};
    width:${(props) => props.fromWrapper===true? '95%' : '352.48px'};
    height:${(props) => props.fromWrapper===true? '280px' : '259px'};
    border-radius: 10px;
    overflow:auto;

`;

export const CardNumber = styled.div`
    text-align: center; 
    font-size: 36px;
    margin-top: 11px;
    font-weight: 600;
`;

export const CardArrow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
`

export const LegendHead = styled.div`
    font-size: 20px;
    margin-right:27px;
    margin-top:2px;
    color: ${({ theme }) => theme.text.low};
`;
export const LegendItem = styled.div`
    display: flex;
`;

export const LegendCount = styled.div`
    margin-top:3px;
    font-size:20px 
`;

export const Legend = styled.div`
     display: flex;
     margin-top:${(props) => props.fromWrapper === true? '12%' : '14px'};
     width:100%;
`;
export const ItemHeading = styled.div`
     margin-top: ${(props) => props.fromWrapper === true? '20px' : '5px'}; 
     font-size: 32px; 
     font-weight: ${(props) => props.fromWrapper === true? '600' : ''};

    @media(max-width: 800px){
        text-align: left;
    }
`;
export const StatusSummary = styled.div`
     margin-top:-9px; 
     font-size: 24px;
     color: ${({ theme }) => theme.text.low};

    @media(max-width: 800px){
    color: ${({theme}) => theme.text.main};
    margin-top:-9px; 
    font-size: 24px;
    width: fit-content   
    }
`
export const Statusapp = styled.div`
     margin-bottom:${(props) => props.fromWrapper === true? props.setshowSidebar===true?'2.2%':"" : '30px'};
     margin-top:${(props) => props.fromWrapper === true? '3.7%'  : '30px'};
     margin-left: ${(props) => props.fromWrapper === true? '3%' : ''};
     width: ${(props) => props.fromWrapper === true? '100%' : ''};
@media only screen and (max-width: 1800px) {
    width:33%;
    dispay:flex;
    flex-direction: row;
    }
@media only screen and (max-width: 1436px) {
    width:49%;
    dispay:flex;
    flex-direction: row;
    }
@media only screen and (max-width: 800px) {
    width:fit-content;
    margin: auto;
    margin-bottom: 30px
}
`;