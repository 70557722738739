import React from 'react';
import './darkModeSwitchStyle.css';
import night from '../../../icons/night-icon/Night.svg'
import NightDay from '../../../icons/night-icon/NightDay.svg'
import DayIcon from '../../../icons/day-icon/day-icon'
import SunDay from '../../../icons/day-icon/SunDay.svg'
import Days from '../../../icons/day-icon/Days.svg'
import styled from 'styled-components';
import useWindowSize from "../../../hooks/use-window-size";
import {ThemebuttonNight, ThemebuttonDay} from "./styled-component"


const DarkModeSwitch = ({theme, toggleTheme, device}: any) => {

  // A switch that displays and shows if its light or dark mode enabled, when clicked it will run hook for changing dark mode state
  // const isDark = theme === 'dark';
  function handleThemeMode(e) {
    toggleTheme(e)
  }
  return(
    <div>
      { device === "mobile" ?
        <div style={{display:"flex",width:"133px",height:"49px",background:theme=="dark"?"#17191D":"",borderRadius:"5px", marginLeft: "0%", marginBottom: "10px"}} >
          <ThemebuttonNight onClick ={() => handleThemeMode("dark")} >
            <div style={{marginTop:"10px", marginLeft: "16px"}}>{theme=="light"?<img src={night} width="25px" height= "27px"/>:<img src={NightDay} width="25px" height= "27px"/>}</div>
            {/* <div style={{marginTop:"6px",marginLeft:"5px",font:"18px"}}>Night</div> */}
          </ThemebuttonNight>
          <ThemebuttonDay onClick ={() => handleThemeMode("light")} >
            {theme === "light"?<input type="image" src= {SunDay} onClick= {handleThemeMode} value={"light"} width="25px" height= "26px" style={{ marginLeft: "-10px" ,marginTop: "6px"}}/> : <input type="image" src= {Days} onClick= {handleThemeMode} value={"light"} width="25px" height= "27px" style={{ marginLeft: "-10px" ,marginTop: "6px"}} className="F2F4F8" />}
          </ThemebuttonDay>
        </div>
      :
        <div style={{display:"flex",width:"198px",height:"39px",background:theme=="dark"?"#17191D":"",borderRadius:"5px", marginTop: "15px"}} >
          <ThemebuttonNight onClick ={() => handleThemeMode("dark")}  >
            <div style={{marginTop:"5px"}}>{theme=="light"?<img src={night} width="23px"/>:<img src={NightDay} width="23px"/>}</div>
            <div style={{marginTop:"6px",marginLeft:"5px",font:"18px"}}>Night</div>
          </ThemebuttonNight>
          <ThemebuttonDay onClick ={() => handleThemeMode("light")} >Day
            <div style={{marginLeft:"-73px"}}><DayIcon altText="Day" scale={0.6} light theme={theme}/></div>
          </ThemebuttonDay>
        </div>
      }
    </div>
  );
};

export default DarkModeSwitch;