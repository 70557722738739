import React, {useEffect} from 'react';

import { BsFillCircleFill } from "react-icons/bs";
import Text from "../../components/text";
import SectionContainer from '../section-container';
import ContentContainer from '../content-container';
import SectionHeader from '../section-header';
import UserMenu from './user-menu';

import {GridLayout} from './styled-components';
import {useIsAuthenticated} from "@azure/msal-react";

import DgiLogo from '../../icons/dgi-logo'
import BaneNorLogo from '../../icons/bane-nor-icon'
import AkerBPLogo from '../../icons/akerbp-icon'
import Soprasteria from '../../icons/soprasteria-icon'
import ExecutiveDashboardIcon from '../../icons/executive-dashboard-icon'

type HeaderProps = {
  /**
   * Text to be displayed in the header
   */
  headerText?: string,
  /**
   * Icon to be displayed instead of text in header
   */
  signage: boolean,
  icon?: React.ReactNode

  theme?: string,
  data: any,
  toggleTheme?: () => void,
  live?: boolean
};

function get_icon(company_name: string ,theme){
  switch (company_name) {
    case "Digitale Gardermoen IS":
      return <a href="/"><DgiLogo scale={1.3} altText="Digitale Gardermoen IS logo" theme={theme}/></a>
    case "Aker BP":
      return <a href="/"><AkerBPLogo scale={1.4} altText="Aker logo" theme={theme}/></a>
    case "Bane NOR":
      return <a href="/"><BaneNorLogo scale={6} altText="Bane NOR logo" theme={theme}/></a>
    case "Sopra Steria":
      return <a href="/"><Soprasteria scale={1.4} altText="Sopra Steria logo" theme={theme}/></a>
    default:
      return <a href="/"><ExecutiveDashboardIcon light={true} scale={1} altText="Sopra Steria logo" theme={theme}/></a>
  }
}

const Header: React.FC<HeaderProps> = ({ data, icon, theme,toggleTheme, live, signage }) => {
  const isAuthenticated = useIsAuthenticated();
  
  return (
      <SectionContainer className="header-section" styling={{backgroundColor: 'header'}}>
        <GridLayout data-cy="header" iconIsDisplayed={icon}>
          <ContentContainer className="header-text-section">
            {data && <SectionHeader textColor="light"></SectionHeader>}
            {get_icon(data ? data['companyName'] : "", theme)}
            {/* {icon && icon} */}
          </ContentContainer>
          <ContentContainer className="user-menu-section">
            {/*@ts-ignore*/}
            {signage ?
                <div style={{"position": "relative", "right": "30px"}}>
                  <BsFillCircleFill size={12} color="red" /><Text size="large" weight="600"> LIVE </Text>
                </div> 
              :
              data && data['userName'] ?
                <UserMenu data={data} theme={theme} toggleTheme={toggleTheme}/>
              :
                <></>}
          </ContentContainer>
        </GridLayout>
      </SectionContainer>
  );
};

export default Header;
