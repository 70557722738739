import React from 'react';

import Typography from '../../styles/typography';

import { StyledText } from './styled-components';

type TextProps = {
  children: React.ReactNode;
  /** Choose from the colorpalette which color you want the text to be displayed in. */
  color?: string;
  /** If true the italic font style would be added for the text. */
  italic?: boolean;
  /** Choose the size: extrasmall, small, normal large, larger or extralarge */
  size?: string;
  /** Choose the weight **/
  weight?: string
};

const sizes = {
  extrasmall: Typography.text.sizes.extrasmall,
  small: Typography.text.sizes.small,
  normal: Typography.text.sizes.normal,
  large: Typography.text.sizes.large,
  larger: Typography.text.sizes.larger,
  extralarge: Typography.text.sizes.extralarge,
  xxlarge: Typography.text.sizes.xxlarge,
  xxxlarge: Typography.text.sizes.xxxlarge
};

const Text: React.FC<TextProps> = ({
  children,
  color ,
  italic = false,
  weight = '400',
  size = 'normal'
}) => {
  return (
    <StyledText
      color={color}
      italic={italic}
      size={sizes[size]}
      weight={weight}
    >
      {children}
    </StyledText>
  );
};

export default Text;