import React, {useEffect, useState} from "react";
import {AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated} from "@azure/msal-react";
import App from "../App";
import Header from "../components/header";
import DgiLogo from "../icons/dgi-logo";
import styled, {ThemeProvider} from 'styled-components';
import {MainLayout, ContentSection, ContentArea} from "../App";
import {BrowserRouter as Router, Route, Routes, Navigate} from "react-router-dom";

import Login from "../pages/login";

import RequestInterceptor from "../api/RequestInterceptor";
import {lightTheme} from "../styles/colors/light-theme";
import {darkTheme} from "../styles/colors/dark-theme";
import useDarkMode from "../hooks/use-dark-mode";


const ApplicationRouter = () => {
    const themeMode = 'light' === 'light' ? lightTheme : darkTheme;
    const [theme, toggleTheme]: any = useDarkMode();
    const [showheader, setShowheader] = useState(false)
    // const [loginstate, setLoginstate] = useState('/login')
    
    var loginstate
    let path = window.location.pathname
    if (path && !path.includes('/login') && path != '/'){
        loginstate = '/login?url=' + path
    }else{
        if (loginstate == '/login'){
        }else{loginstate = '/login'}}
    
    useEffect(() => {
        const login_page = window.location.href.indexOf('/login') > -1;
        if (login_page){
            setShowheader(false)
        }
    }, []);
    return (
        <>
            <AuthenticatedTemplate>
                <RequestInterceptor>
                    <App/>
                </RequestInterceptor>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <MainLayout>
                    <ThemeProvider theme={themeMode}>
                        {/* {showheader ? <Header
                            data={""}
                            signage={false}
                            icon={<a href="/"><DgiLogo scale={1.3} altText="Executive Dashboard logo" theme={theme}/></a>}
                        />: <></>} */}
                            <Router>
                                    <Routes>
                                        <Route caseSensitive={false} path={'/login'} element={<Login light={true} theme={theme}/>} />
                                        <Route
                                            path="*"
                                            element={<Navigate to={loginstate} />}
                                        />
                                    </Routes>
                            </Router>
                    </ThemeProvider>
                </MainLayout>
            </UnauthenticatedTemplate>
        </>
    );
};

export default ApplicationRouter;