import styled from "styled-components";

export const StyledDonutChart = styled.div`
  
  .donut-chart-inner-number {
    fill: ${({ theme }) => theme.text.main };
  }
  
  .donut-chart-inner-text {
    fill: ${({ theme }) => theme.text.main };
  }
  
  .donut-chart-circle-text {
    fill: ${({ theme }) => theme.text.secondary };
  }
  }
  
`;