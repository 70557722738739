import React from 'react';
import { IconWrapper } from '../styled-components';
import { ReactComponent as InfoIconBlack } from './info-icon-black.svg';
import { ReactComponent as InfoIconWhite } from './info-icon-white.svg';

type InfoIconProps = {
  altText: string;
  isLightTheme: boolean;
};

const InfoIcon: React.FC<InfoIconProps> = ({ altText, isLightTheme }) => {
  return (
    <IconWrapper width={2} height={2} aria-label={altText}>
      {
        isLightTheme ? <InfoIconBlack /> : <InfoIconWhite />
      }
    </IconWrapper>
  );
};

export default InfoIcon;
