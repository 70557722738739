import styled from 'styled-components';

import Colors from '../../../styles/colors';
import Typography from "../../../styles/typography";

type Props = {
  isOpen?: boolean;
  ref?: any,
}

type PropsS = {
  weight?: string,
  size?: string,
}


export const UserMenuWrapper = styled.div<Props>`
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: auto 0.6rem auto 0.4rem auto 1rem;
  grid-template-areas: 'icon . name . arrow .';
  cursor: pointer;

  .hamburger-user-menu {
    grid-area: arrow;
    
    :focus {
      outline: 0;
      box-shadow: none;
      border: none;
    }
    
    .fx-hamburger-line {
      background-color: white;
    }
    
  }

  .icon-container {
    grid-area: icon;
  }

  .user-container {
    grid-area: name;
  }

  .arrow-icon-container {
    grid-area: arrow;
    
    & svg > g > line {
      stroke: ${({ theme }) => theme.text.main };
    }
    
  }
  
  @media only screen and (max-width: 800px) {
    display: grid;
    align-items: center;
    grid-template-columns: auto;
    grid-template-areas: 'hamburger';
    cursor: pointer;
  }
  
`;

export const CollapseDiv = styled.div`
  position: absolute;
  right: 0px;
  width: 100%;
  height: 185px;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  
  display: grid;
  align-items: center;
  grid-template-columns: 2rem 1fr 2rem;
  grid-template-rows: 1rem 1fr 1fr 1fr 1rem;
  grid-template-areas: 
      '. . .'
      '. user .'
      '. darkmode .'
      '. logout .'
      '. . .';
   
   :before {
    content:"";
    position: absolute;
    top:-4px;
    right: 20px;
    margin:auto;
    height: 15px;
    width: 15px;
    transform:rotate(45deg);
    background-color: ${({ theme }) => theme.openUserMenu };
  }  
   
  .username-container {
    grid-area: user;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 2rem 1fr;
    grid-template-areas: 'text username username';
    
    text-align: left;
    
    .user-text {
      grid-area: text;
    }
    
    .name-text {
      text-align: right;
      grid-area: username;
    }
    
  }
  
  .darkmode-container {
    grid-area: darkmode;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 2rem 1fr;
    
    text-align: left;
    
    grid-template-areas: 'text switch switch';
    
    .dark-mode-text {
      grid-area: text;
    }
    
    .dark-mode-switch {
      grid-area: switch;
      .switch {
        float: right;
      }
    }
  }
      
  .logout-button-container {
    grid-area: logout;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1rem 1fr;
    grid-template-areas: 'logout-button . .';
    
    text-align: left;
    
    .logout-button {
      grid-area: logout-button;
    }
    
  }
  background-color: ${({ theme }) => theme.openUserMenu };

  @media only screen and (max-width: 800px) {
    
    .username-container{
      grid-template-columns: 1fr;
    }
    .logout-button-container{
      grid-template-columns: 1fr;
    }
  }
  
`;

export const UserNameContainer = styled.div`
  display: grid;
  grid-template-rows: 2/3 1/3;
  grid-template-areas: 
     'username' 
     'company';
`;

export const LogoutButton = styled.div`
  background-color: transparent;
  cursor: pointer;

  &:hover,
  &:active {
    color: ${Colors.darkGray};
  }
`;

export const TextGray = styled.span`
  font-family: ${Typography.text.name};
  weight: ${(props: PropsS) => props.weight};
  font-size: ${(props: PropsS) => props.size};
  color: ${({ theme }) => theme.colors.darkerGray}!important;
`;