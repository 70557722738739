import {useState} from "react";
import constate from "constate";

function useMenu() {
    const [navigationToggle, setNavigationToggle] = useState(true);
    const [checkpostcomplete, setCheckpostcomplete] = useState(false);

    return {
        navigationToggle: navigationToggle,
        setNavigationToggle: setNavigationToggle,
        checkpostcomplete: checkpostcomplete,
        setCheckpostcomplete: setCheckpostcomplete,
    };
}

const [
    MenuProvider,
    useNavigationToggle,
    useSetNavigationToggle,
    useCheckpostcomplete,
    useSetCheckpostcomplete
] = constate(
    useMenu,
    value => value.navigationToggle,
    value => value.setNavigationToggle,
    value => value.checkpostcomplete,
    value => value.setCheckpostcomplete
);

export {
    MenuProvider,
    useNavigationToggle,
    useSetNavigationToggle,
    useCheckpostcomplete,
    useSetCheckpostcomplete
}