import styled from 'styled-components';

import Colors from '../../../../styles/colors';

export const ButtonWrapper = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    height: 12rem;
    background-color: ${({ theme }) => theme.main ? theme.main : Colors.white};
    border: thin solid ${({ theme }) => theme.main ? theme.main : Colors.white};
    box-shadow: 0 4px 3px ${({ theme }) => theme.shadow.gray ? theme.shadow.gray : Colors.gray};
    text-decoration: none;
    row-gap: 1rem;
    cursor: pointer;
`;

export const IconWrapper = styled.div``;