import styled from "styled-components";

export const ButtonGrid = styled.div`
  width: 100%;
  display: flex;
`;

type Props = {
  active?: boolean
};

export const StyledButton = styled.button`
  border: 1px solid ${( props: Props ) => props.active ? ({ theme }) => theme.mainFont : ({ theme }) => theme.text.low};
  color: ${( props: Props ) => props.active ? ({ theme }) => theme.mainFont : ({ theme }) => theme.text.low}; 
 background-color: ${( props: Props ) => props.active ? ({ theme }) => theme.Font : "transparent"};
  padding: 0.5rem;
  width: 100%;
  
  :disabled {
    background-color: transparent;
    border: 1px solid lightgray;
    color: gray;
    :hover {
      background-color: transparent;
    }
  }
  
  :hover {
    background-color: ${({ theme }) => theme.shadow.gray};
    color: ${({ theme }) => theme.mainFont};
  }
  
`;