import styled from 'styled-components';

export const DescriptionWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-flow: column;
    row-gap: 0.5rem;
`;

type ColorBoxProps = {
    color?: string,
    negative?: boolean,
    attention?: boolean,
    positive?: boolean
}

export const ColorBox = styled.div<ColorBoxProps>`
    width: 1rem;
    height: 1rem;
    background-color: ${(props: ColorBoxProps) => props.color ?
                                                              props.color
                                                              :
                                                              props.negative ? 
                                                                  ({theme}) => theme.colors.negative
                                                                  : 
                                                                  props.attention ?
                                                                      ({theme}) => theme.colors.attention
                                                                      : 
                                                                      props.positive ? ({theme}) => theme.colors.positive : "#000000"}
`;