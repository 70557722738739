import styled from "styled-components";
import Typography from "../../styles/typography";



export const FinanceSummaryWrapper = styled.div`
  grid-area: bar-chart;
  display: grid;
  grid-template-areas:
    '. header .'
    '. . .'
    '. chart .'
    '. . .';

  padding: 2rem;
  background-color: ${({ theme }) => theme.main};
  border-radius: 0.625rem;
`;

export const FinanceSummaryHeader = styled.div`
  grid-area: header;
  display: flex;
  column-gap: 1rem;

`;

export const TooltipWrapper = styled.div`
  padding: 0.5rem 0;
`;

export const FSHeaderText = styled.div`
  padding: 0.5rem 0;
  margin: 0;
  color: ${({ theme }) => theme.text.main};
  font-family: ${Typography.text.name};
  font-weight: ${Typography.text.semibold};
  font-size: ${Typography.text.sizes.xxlarge};
  line-height: ${Typography.text.lineHeight.xxlarge};
`;

export const FinanceSummaryBarChartContainer = styled.div`
  grid-area: chart;

`;