import React from 'react';

import { IconWrapper } from '../styled-components';

import { ReactComponent as Day } from './Day-icon.svg';
import { ReactComponent as DayDark } from './Day.svg';
import styled from "styled-components";

const StyledDayIcon = styled(Day)`
  
`
const StyledDayIconDark = styled(DayDark)`
  
`

type DayIconProps = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale?: number;
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: string;
  light?: boolean;
  theme:any;
};

const DayIcon: React.FC<DayIconProps> = ({ scale = 1, altText, light,theme }) => {
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText}>
      {theme==="light" ? <StyledDayIcon /> : <StyledDayIconDark /> }
    </IconWrapper>
  );
};

export default DayIcon;