import styled from 'styled-components';

type StatusWrapperProps = {
  height: boolean
}


export const StatusWrapper = styled.div`
  margin-top:30px;
  margin-bottom:30px;
  display: grid;
  color: ${({ theme }) => theme.text.main};
  min-width: 30rem;
  height: ${(props: StatusWrapperProps) => props.height ? '19rem' : ''};
  grid-template-columns: 10rem 1fr;
  grid-template-areas:
        '. .'
        'chart chart'
        '. .';
  border-radius: 0.625rem;

  .bar-chart-section {
    grid-area: chart;
  }
  background-color: ${({ theme }) => theme.main};
  text-decoration: none;
  padding: 12px;
`;

export const ChartDescriptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
  width: 10rem;
`;

export const DescriptionTextWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
