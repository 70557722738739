import React, {useEffect, useState} from 'react';

import Text from '../text';
import ContentContainer from '../content-container';

import {withTheme} from "styled-components";

import DonutChart from '../DonutChart/donutchart';
import ChartDescription from '../chart-description';

import {ChartDescriptionWrapper, DescriptionTextWrapper, Heading, StatusWrapper} from './styled-components';
import useWindowSize from "../../hooks/use-window-size";

type ChartDonutProps = {
    id: string
    header: string,
    alerts: any,
    showNumber: boolean,
    theme: {
        light: boolean,
    }
    fromWrapper: boolean;
    bold: any;
    italic: any;
    fontsize: any;
    speedometerlegth: number;
};

type ChartData = {
    label: string,
    value: number,
    description: string,
    color: string
};

const ChartDonut: React.FC<ChartDonutProps> = ({ id, header, alerts, theme, showNumber, fromWrapper, bold, italic, fontsize, speedometerlegth}) => {
    const [data, setData] = useState<ChartData[]>();
    const device = useWindowSize();
    
    useEffect(() => {
        var chartData: ChartData [] = [];
        
        if(alerts["ColorSections"]){
            alerts["ColorSections"].map((section: any, index: number) => {
                chartData.push({
                    label: index.toString(),
                    value: parseInt(section["ColorSectionCount"]),
                    description: (alerts["LegendItem"] && alerts["LegendItem"].length) ? alerts["LegendItem"][index]["LegendHeading"] : "",
                    color: section["Color"],
                })
            })
        }
        setData(chartData);
    }, [alerts, theme]);

    function get_outer_radius(data){
        let greatest_number = 0
        data && data.map((item, index) => {
            if (greatest_number < parseInt(item.value)){
                greatest_number = parseInt(item.value)
            }
        })
        if (greatest_number > 1000){
            return 95
        }
        else if (greatest_number > 100){
            return 95
        } 
        
        return 80
    }

    return (
        data && alerts["CenterNumber"] && alerts['StatusSummary'] ? 
        <StatusWrapper fromWrapper={fromWrapper} speedometerlegth = {speedometerlegth}>
            <ContentContainer className="service-chart-header-section">
                { fromWrapper === true?
                    <Heading style={{fontWeight: bold && bold === '1'? 'bold' : '', fontStyle: italic && italic === '1'? 'italic' : '', fontSize: fontsize? fontsize+'px': '16px', textTransform: 'uppercase'}} >
                        {header}
                    </Heading> 
                    : 
                    <Heading style={{fontWeight: bold && bold === '1'? 'bold' : '', fontStyle: italic && italic === '1'? 'italic' : '', fontSize: fontsize? fontsize+'px': '16px' }} >
                        {header}
                    </Heading> }

                {/* <Text size="large">{header}</Text> */}
            </ContentContainer>
            <ContentContainer className="service-chart-section">
                {data &&
                    <DonutChart
                        id={`_${id}-chart`}
                        data={data}
                        total={parseInt(alerts["CenterNumber"])}
                        centerText={alerts["CenterText"]}
                        outerRadius={device === "mobile" ? speedometerlegth === 1? 95 : 58 : get_outer_radius(data)}
                        innerRadius={device === "mobile" ? speedometerlegth === 1? 63 : 38 : 55}
                        isEmpty={alerts["ColorSections"][0]["ColorSectionCount"] === "0"}
                        isMobile={device === "mobile"}
                        showNumber={showNumber}
                        speedometerlegth = {speedometerlegth}
                    />
                }
            </ContentContainer>
            {device === "mobile" ? <></> : <ContentContainer className="service-chart-description-section">
                {(alerts["LegendItem"].length) ?
                    (<ChartDescriptionWrapper fromWrapper={fromWrapper}>
                        {alerts && alerts["LegendItem"].map((item: any, i: number) => {
                            return(
                                <ChartDescription
                                    key={`${i}-${item["LegendHeading"]}-description`}
                                    description={item["LegendHeading"]}
                                    color={item["LegendColor"]}
                                />
                            )
                        })}
                    </ChartDescriptionWrapper>)
                    :
                    (<DescriptionTextWrapper>
                        <Text size="large">{alerts['StatusSummary']}</Text>
                    </DescriptionTextWrapper>)
                }
            </ContentContainer>}
        </StatusWrapper>
        :
        <StatusWrapper fromWrapper={fromWrapper}>
            <ContentContainer className="service-chart-header-section">
                <Text size="large"> </Text>
            </ContentContainer>
            <ContentContainer className="service-chart-section">
                
            </ContentContainer>
            <ContentContainer className="service-chart-description-section">
                    <ChartDescriptionWrapper>
                        
                    </ChartDescriptionWrapper>
            </ContentContainer>
        </StatusWrapper>
    );
};

export default withTheme(ChartDonut);