import React from 'react';

import { IconWrapper } from '../styled-components';

import { ReactComponent as AkerBP } from './AkerBP-Logo_H_light-text.svg';
import { ReactComponent as AkerBPDark } from './AkerBP-Logo_H_dark-text.svg';
import styled from "styled-components";

const StyledAkerBPIcon = styled(AkerBP)`
  
`
const StyledAkerBPIconDark = styled(AkerBPDark)`
  
`

type AkerBPIconProps = {
    /**
     * Scale the icon relative to a base size of 2rem
     */
    scale?: number;
    /**
     * An alternative text that is read by a screen reader for accessibility purposes.
     */
    altText: string;
    light?: boolean;
    theme:any;
};

const AkerBPIcon: React.FC<AkerBPIconProps> = ({ scale = 1, altText, light ,theme}) => {
    return (
        <IconWrapper width={2 * scale} aria-label={altText}>
            {theme==="dark" ? <StyledAkerBPIcon /> : <StyledAkerBPIconDark /> }
        </IconWrapper>
    );
};

export default AkerBPIcon;