import React from "react";

import { IconWrapper } from "../styled-components";

import { ReactComponent as requestSubmit } from "./Request_sent_ED.svg";
import styled from "styled-components";

const RequestSubmitLOGO = styled(requestSubmit)``;


type RequestSubmitLogoProps = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale?: number;
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: string;
  /* light or dark mode*/
  light?: boolean;
};

const RequestSubmitIcon: React.FC<RequestSubmitLogoProps> = ({
  scale = 1,
  altText,
  light,
}) => {
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText}>
      <RequestSubmitLOGO />
    </IconWrapper>
  );
};

export default RequestSubmitIcon;
