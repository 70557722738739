import React from "react";

import DashboardButton from "./components/dashboard-button";
import { useMsal } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from "../../authentication/authConfig";
import styled from "styled-components";

import SopraSteriaIcon from "../../icons/sopra-steria-icon";
import BaneNorIcon from "../../icons/bane-nor-icon";
import AkerBPIcon from "../../icons/aker-bp-icon/aker-bp-icon";
import PageHeader from "../../components/page-header";
import DgiLogo from "../../icons/dgi-logo";
import EquinorLogo from "../../icons/equinor-icon";
import WalleniuswilhelmsenIcon from "../../icons/wallenius-whilhelmsen-icon/wallenius-wilhelmsen";
import DrammenKommuneIcon from "../../icons/drammenKommuneIcon";

function handleLogin(instance: IPublicClientApplication, domainHint): void {
  instance.loginRedirect(loginRequest(domainHint)).catch((e) => {
    throw e;
  });
}

type LoginProps = {
  light: boolean;
  theme: any;
};

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  column-gap: 2.5rem;
  row-gap: 2rem;
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(30rem, 45rem) 1fr;
  grid-template-rows:
    1rem
    min-content
    1rem
    1fr
    1rem;
  grid-template-areas:
    ". . ."
    ". header ."
    ". . ."
    ". clients ."
    ". . .";

  .header-container {
    grid-area: header;
  }

  .clients-container {
    grid-area: clients;
  }

  @media (max-width: 640px) {
    grid-template-columns: 100%;
    grid-template-rows:
      5rem
      min-content
      1rem
      1fr
      1rem;
    grid-template-areas:
      "."
      "header"
      "."
      "clients"
      ".";

    .header-container {
      text-align: center;
    }

    .navigation-section {
      border-radius: 0;
    }
  }
`;

const Login: React.FC<LoginProps> = (light, theme) => {
  const { instance } = useMsal();

  return (
    <ContentContainer>
      <div className="header-container">
        <PageHeader>Customer Operations Status</PageHeader>
      </div>
      <GridContainer className="clients-container">
        <DashboardButton
          icon={
            <AkerBPIcon
              scale={7}
              altText={"Aker BP"}
              light={!light}
              theme={theme}
            />
          }
          onClick={() => handleLogin(instance, "akerbp.com")}
        />
        <DashboardButton
          icon={
            <BaneNorIcon
              scale={8}
              altText="bane nor logo"
              light={!light}
              theme={theme}
            />
          }
          onClick={() => handleLogin(instance, "banenor.no")}
        />
        <DashboardButton
          icon={
            <DgiLogo
              scale={4}
              altText="DGI logo"
              light={!light}
              theme={theme}
            />
          }
          onClick={() => handleLogin(instance, "dgi.no")}
        />
        <DashboardButton
          icon={
            <EquinorLogo scale={5} altText="sopra steria logo" light={!light} />
          }
          onClick={() => handleLogin(instance, "equinor.com")}
        />
        <DashboardButton
          icon={
            <SopraSteriaIcon
              scale={2}
              altText="sopra steria logo"
              light={!light}
              theme={theme}
            />
          }
          onClick={() => handleLogin(instance, "")}
        />
        <DashboardButton
          icon={
            <WalleniuswilhelmsenIcon
              scale={1}
              altText="Wallenius wilhelmsen logo"
            />
          }
          onClick={() => handleLogin(instance, "walleniuswilhelmsen.com")}
        />
        <DashboardButton
          icon={
            <DrammenKommuneIcon scale={5} altText="drammen_kommuneIcon logo" />
          }
          onClick={() => handleLogin(instance, "drammen.kommune.no")}
        />
        {/*<DashboardButton
          text="Bad Company"
          icon={null}
          onClick={() => login('badcompanytesttenant.onmicrosoft.com')}
        />
        <DashboardButton
          text="Main organization"
          icon={null}
          onClick={() => login('mainorgtesttenant.onmicrosoft.com')}
        />
        <DashboardButton
          text="Customer1"
          icon={null}
          onClick={() => login('customer1testtenant.onmicrosoft.com')}
        />
        <DashboardButton
          text="Customer2"
          icon={null}
          onClick={() => login('customer2testtenant.onmicrosoft.com')}
        />*/}
      </GridContainer>
    </ContentContainer>
  );
};

export default Login;
