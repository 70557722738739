import React from 'react';

import { IconWrapper } from '../styled-components';

import { ReactComponent as DGILOGO } from './dgi-logo.svg';
import styled from "styled-components";

const StyledDGILogo = styled(DGILOGO)`
  
`

const StyledSecurityIconDark = styled(DGILOGO)`
  
`

type DGILogoProps = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale?: number;
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: string;
  /* light or dark mode*/
  light?: boolean;
  theme:any;
};

const DgiLogo: React.FC<DGILogoProps> = ({ scale = 1, altText, light ,theme}) => {
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText} style={{"marginTop": "2px", marginLeft: "15px"}}>
      <StyledDGILogo />
    </IconWrapper>
  );
};

export default DgiLogo;