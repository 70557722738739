import React from 'react';
import useWindowSize from "../../hooks/use-window-size";
import '../../styles/ui/ui.css';
import {CardItem,LegendHead,CardNumber,LegendItem,LegendCount,Legend,ItemHeading,StatusSummary,Statusapp,CardArrow} from './styled-components'
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Badge from 'react-bootstrap/Badge'
import CardUpArrow from '../../icons/CardUpArrow';
import CardDownArrow from '../../icons/CardDownArrow';

type CarditemProps = {
    json: any;
    fromWrapper: boolean;
};

const CardComponent: React.FC<CarditemProps> = ({ json, fromWrapper }) => {
    const device = useWindowSize();
    
    return (
        <Row>
            {json['CardItem'].map((itr: any[], c_index: any) => {
                return (
                    <Col xxl={3} lg={4} xl={4} md={6} sm={6} as={Statusapp} key={c_index} fromWrapper={fromWrapper}>
                        <CardItem fromWrapper={fromWrapper}>
                            <div style={{width: "fit-content", display: 'flex'}}>
                            {/* <Badge
                                ref={(el) => {
                                    if (el) {
                                        el.style.setProperty('background-color', itr['CenterBackgroundColor'], 'important');
                                    }
                                }}
                                style={{ color: itr['CenterTextColor'] ? itr['CenterTextColor'] : '',width:(itr['CenterNumber']).length==1?"64px":"fit-content" , height: '64px' }}>
                                    <CardNumber>{itr['CenterNumber']}</CardNumber>
                            </Badge> */}
                                <CardNumber>{itr['CenterNumber']}</CardNumber>
                                { itr['ArrowDirection'] && itr['ArrowDirection'] === 'Up'? 
                                    <CardArrow> <CardUpArrow scale={0.6} altText={'CardUpArrow'} /> </CardArrow> 
                                    :   
                                    <CardArrow> <CardDownArrow scale={0.6} altText={'CardDownArrow'}/> </CardArrow>
                                }
                            </div>
                            <ItemHeading fromWrapper={fromWrapper}> {itr['ItemHeading']}</ItemHeading>
                            <StatusSummary>{itr['StatusSummary']}</StatusSummary>
                            <Legend fromWrapper={fromWrapper}>
                                {itr['LegendItem'].map((legen: any[], lege_card: any) => {
                                    return (
                                        <LegendItem  key={lege_card}>
                                            <Badge
                                                ref={(el) => {
                                                    if (el) {
                                                        el.style.setProperty('background-color', legen['LegendBackgroundColor'], 'important');
                                                    }
                                                }}
                                                style={{ color: legen['LegendTextColor'] ? legen['LegendTextColor'] : '', width:(legen['LegendSectionCount']).length==1?"35px":"fit-content", height: '35px',marginRight:'14px' }}>
                                                <LegendCount>{legen['LegendSectionCount']}</LegendCount>
                                            </Badge>
                                            <LegendHead>{legen['LegendHeading']}</LegendHead>
                                        </LegendItem>
                                    )
                                })}
                            </Legend>
                        </CardItem>
                    </Col>
                )
            })}
        </Row>
    );
};

export default CardComponent;