import React from 'react';

import { IconWrapper } from '../styled-components';

import { ReactComponent as walleniuswilhelmsen } from '../wallenius-whilhelmsen-icon/wallenius-wilhelmsen.svg';
import styled from "styled-components";

const StyledWalleniuswilhelmsenIcon = styled(walleniuswilhelmsen)`

`

type WalleniuswilhelmsenIconProps = {
    /**
     * Scale the icon relative to a base size of 2rem
     */
    scale?: number;
    /**
     * An alternative text that is read by a screen reader for accessibility purposes.
     */
    altText: string;
    // light?: boolean;
    // theme:any;
};

const WalleniuswilhelmsenIcon: React.FC<WalleniuswilhelmsenIconProps> = ({ scale = 1, altText }) => {
    return (
        <IconWrapper width={20 * scale} aria-label={altText} style={{ marginTop: "18px"}}>
            <StyledWalleniuswilhelmsenIcon />
        </IconWrapper>
    );
};

export default WalleniuswilhelmsenIcon;