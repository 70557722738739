import React from 'react';

import Text from '../text';

import { DescriptionWrapper, ColorBox } from './styled-components'; 

type ChartDescriptionProps = {
    description: string,
    color?: string,
    negative?: boolean,
    attention?: boolean,
    positive?: boolean
}

const ChartDescription: React.FC<ChartDescriptionProps> = ({description, color, negative, attention, positive}) => {
    return (
        <DescriptionWrapper>
            <ColorBox color={color} negative={negative} attention={attention} positive={positive}/>
            <Text>{description}</Text>
        </DescriptionWrapper>
    );
};

export default ChartDescription;