import styled from 'styled-components';

import Typography from '../../styles/typography';


export const StyledSectionHeader = styled.h3`
  font-family: ${Typography.text.name};
  font-weight: ${Typography.text.semibold};
  font-size: ${Typography.text.sizes.xxlarge};
  color: white;
  color: ${({theme}) => theme.secondaryFont};
  margin: 0;
  display: inline;
`;
