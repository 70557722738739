import React from 'react'

type SnappyProps={
    imageSource: any
}

const Snappy: React.FC<SnappyProps> = ({ imageSource }) =>{
    return(
        <div>
            <img style={{width: '100%'}} src={imageSource} alt="Snappy" />
        </div>
    )
}

export default Snappy;