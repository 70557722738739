import React, {useState, useEffect} from 'react'

import axios from "axios";

import mainLogo from '../../icons/download.png'

const ImageComponent = ({}) => {
    const [data, setData] = useState(false)
    
    function get_data(){
        let url = window.location.href
        let sn_url = 'https://soprasteria@@@.service-now.com/api/x_steas_executive/2s_executive_dashboard/getattachmentmetadata'
        const isLocalhost = window.location.href.indexOf('localhost') > -1;
        if (url.indexOf('-test') > -1){
            sn_url = sn_url.replace('@@@', 'test')}
        else if (url.indexOf('-dev') > -1){
            sn_url = sn_url.replace('@@@', 'dev')}
        else if (url.indexOf('-prod') > -1){
            sn_url = sn_url.replace('@@@', '')}
        else{
            sn_url = sn_url.replace('@@@', '')}

        axios.get(sn_url).then((data)=>{
            setData(data['data']['result']['base64'])
            // setData(data)
        }).catch((error) =>{
            console.log(error);
        })
    } 
    useEffect (() => {
        get_data()
        let wait = 120
        const interval = setInterval(() => {
        // if(!document.hidden){
            get_data()
        // }
        }, (wait*1000));
        return () => {clearInterval(interval);}
    },[])

    return (
        <div>
            {data? 
                <img style={{width: '100%'}} src={`data:image/png;base64,${data}`} alt="fireSpot"/>
                :
                <h1>Loading...</h1>
            }
        </div>
    )
}

export default ImageComponent