import React from 'react';
import PropTypes from 'prop-types';
import {StyledSectionContainer} from './styled-components';

export type SectionContainerProps = {
    children: React.ReactNode;
    className?: string;
    styling?: {
      removeDefaultPadding?: boolean;
      mobileOffset?: boolean;
      backgroundColor?: string;
      backgroundColorDesktopOnly?: string;
      backgroundColorTabletOnly?: string;
      backgroundColorMobileOnly?: string;
      paddingAllSides?: string;
      paddingTop?: string;
      paddingBottom?: string;
      paddingLeft?: string;
      paddingRight?: string;
      paddingAllSidesDesktopOnly?: string;
      paddingTopDesktopOnly?: string;
      paddingBottomDesktopOnly?: string;
      paddingLeftDesktopOnly?: string;
      paddingRightDesktopOnly?: string;
      paddingAllSidesTabletOnly?: string;
      paddingTopTabletOnly?: string;
      paddingBottomTabletOnly?: string;
      paddingLeftTabletOnly?: string;
      paddingRightTabletOnly?: string;
      paddingAllSidesMobileOnly?: string;
      paddingTopMobileOnly?: string;
      paddingBottomMobileOnly?: string; 
      paddingLeftMobileOnly?: string;
      paddingRightMobileOnly?: string;
      marginAllSides?: string;
      marginTop?: string;
      marginBottom?: string;
      marginLeft?: string;
      marginRight?: string;
      marginAllSidesDesktopOnly?: string;
      marginTopDesktopOnly?: string;
      marginBottomDesktopOnly?: string;
      marginLeftDesktopOnly?: string;
      marginRightDesktopOnly?: string;
      marginAllSidesTabletOnly?: string;
      marginTopTabletOnly?: string;
      marginBottomTabletOnly?: string;
      marginLeftTabletOnly?: string;
      marginRightTabletOnly?: string;
      marginAllSidesMobileOnly?: string;
      marginTopMobileOnly?: string;
      marginBottomMobileOnly?: string;
      marginLeftMobileOnly?: string;
      marginRightMobileOnly?: string;
    };
    theme?: {
      light: boolean;
    }
};

export default function SectionContainer(props: SectionContainerProps) {
  return (
    <StyledSectionContainer className={props.className} styling={props.styling} theme={props.theme}>
      {props.children}
    </StyledSectionContainer>
  );
}

SectionContainer.propTypes = {
  /** Content that should be wrapped with this container */
  children: PropTypes.node,
  styling: PropTypes.shape({
    /** Turns of default padding */
    removeDefaultPadding: PropTypes.bool,
    /** Turns on mobile offset */
    mobileOffset: PropTypes.bool,
    /** Sets bakground-color for all devices. One of 'white', 'header', 'footer', 'history' (Same goes for background overrides bellow) */
    backgroundColor: PropTypes.oneOf(['header', 'dark', 'light']),
    /** Overrides / sets bakground-color on desktop only*/
    backgroundColorDesktopOnly: PropTypes.oneOf([
      'header',
      'dark',
      'light'
    ]),
    /** Overrides / sets bakground-color on tablet only*/
    backgroundColorTabletOnly: PropTypes.oneOf([
      'header',
      'dark',
      'light'
    ]),
    /** Overrides / sets bakground-color on mobile only*/
    backgroundColorMobileOnly: PropTypes.oneOf([
      'header',
      'dark',
      'light'
    ]),
    /** Sets padding on all sides. One of 'none' 'lowest' 'low', 'medium', 'high' (Same goes for all padding options bellow)*/
    paddingAllSides: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Sets padding-top for every device*/
    paddingTop: PropTypes.oneOf(['none', 'lowest', 'low', 'medium', 'high']),
    /** Sets padding-bottom for every device*/
    paddingBottom: PropTypes.oneOf(['none', 'lowest', 'low', 'medium', 'high']),
    /** Sets padding-left for every device*/
    paddingLeft: PropTypes.oneOf(['none', 'lowest', 'low', 'medium', 'high']),
    /** Sets padding-right for every device*/
    paddingRight: PropTypes.oneOf(['none', 'lowest', 'low', 'medium', 'high']),
    /** Overrides / sets padding all sides on desktop*/
    paddingAllSidesDesktopOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides / sets padding-top on desktop*/
    paddingTopDesktopOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides / sets padding-bottom on desktop*/
    paddingBottomDesktopOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides / sets padding-left on desktop*/
    paddingLeftDesktopOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides / sets padding-right on desktop*/
    paddingRightDesktopOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides padding all sides on tablet*/
    paddingAllSidesTabletOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides padding-top on tablet*/
    paddingTopTabletOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides padding-bottom on tablet*/
    paddingBottomTabletOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides padding-left on tablet*/
    paddingLeftTabletOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides padding-right on tablet*/
    paddingRightTabletOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides padding all sides on mobile*/
    paddingAllSidesMobileOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides padding-top on mobile*/
    paddingTopMobileOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides padding-bottom on mobile*/
    paddingBottomMobileOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides padding-left on mobile*/
    paddingLeftMobileOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides padding-right on mobile*/
    paddingRightMobileOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Sets margin on all sides. One of 'none' 'lowest' 'low', 'medium', 'high' (Same goes for all margin options bellow)*/
    marginAllSides: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Sets margin-top for every device*/
    marginTop: PropTypes.oneOf(['none', 'lowest', 'low', 'medium', 'high']),
    /** Sets margin-bottom for every device*/
    marginBottom: PropTypes.oneOf(['none', 'lowest', 'low', 'medium', 'high']),
    /** Sets margin-left for every device*/
    marginLeft: PropTypes.oneOf(['none', 'lowest', 'low', 'medium', 'high']),
    /** Sets margin-right for every device*/
    marginRight: PropTypes.oneOf(['none', 'lowest', 'low', 'medium', 'high']),
    /** Overrides / sets margin on all sides on desktop*/
    marginAllSidesDesktopOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Sets margin-top on desktop*/
    marginTopDesktopOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Sets margin-bottom on desktop*/
    marginBottomDesktopOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Sets margin-left on desktop*/
    marginLeftDesktopOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Sets margin-right on desktop*/
    marginRightDesktopOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides margin all sides on tablet*/
    marginAllSidesTabletOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides margin-top on tablet*/
    marginTopTabletOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides margin-bottom on tablet*/
    marginBottomTabletOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides margin-left on tablet*/
    marginLeftTabletOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides margin-right on tablet*/
    marginRightTabletOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides margin all sides on mobile*/
    marginAllSidesMobileOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides margin-top on mobile*/
    marginTopMobileOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides margin-bottom on mobile*/
    marginBottomMobileOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides margin-left on mobile*/
    marginLeftMobileOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides margin-right on mobile*/
    marginRightMobileOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ])
  }),
  theme: PropTypes.shape( {
    light: PropTypes.bool
  })
};
