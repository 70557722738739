import React, { useEffect, useState } from "react";
import styled from "styled-components";
import lock from "./unauth.png";
import { useMsal } from "@azure/msal-react";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Dropdown } from "react-bootstrap";
import { useApiAction } from "../../../hooks/use-api-action/use-api-action";
import { getSNJSON } from "../../../api";
import RequestSubmitIcon from "../../../icons/requestSubmit-icon";
import { useCheckpostcomplete, useSetCheckpostcomplete } from "../../../hooks/useMenuContext/useMenuContext";

type UnauthProps = {
  light: boolean;
  theme: any;
  massage: any;
};

// Style:


const UnauthPage = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
  text-align: center;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  background: #e8e9eb;
`;
const ImageInfo = styled.div`
    // top: 30%;
    // left: 33%;
    // width: 34%;
    width: 407px;
    // height: 60%;
    height: 353px;
    // position: relative;
    // margin: auto;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    background: #FFF;
    border-radius: 16px;
    // padding: 40px;

    .image_size{
        width: 200px;
        opacity: 0.9;
    }

    @media(man-width: 998px){
        left: 34%;
        width: 32%
        hight: fit-content;
    }
    @media(max-width: 800px){
        top: 30%;
        left: 15%;
        width: 70%;
        height: fit-content;
        .image_size{
            width: 120px;
        } 
    }

    @media(max-width: 600px){
        top:25%;
        left: 10%;
        width: 80%;
        height: fit-content;

        .image_size{
            width: 100px;
        }     


    }
`;
const HomePage = styled.button`
  width: 200px;
  height: 40px;
  border: 1px solid gray;
  border-radius: 5px;
  background-color: #dadee1;
  text-align: center;
  margin-top: 30px;
  font-size: 20px;
  font-weight: 500;

  @media (max-width: 800px) {
    margin-top: 20px;
    width: 130px;
    font-size: 16px;
  }

  @media (max-width: 600px) {
    margin-top: 20px;
    width: 120px;
    font-size: 16px;
  }
`;
const Text = styled.p`
  margin-top: 20px;
  font-size: 20px;
  font-weight: 500;

  @media (max-width: 800px) {
    font-size: 19px;
  }

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const Message = styled.div`
  font-family: sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 316px;
  height: fit-content;
  word-break: normal;
  margin: auto;
  // border: 1px solid red;

  @media (max-width: 600px) {
    width: 230px
  }
  @media (max-width: 300px){
    width: 150px;
  }

`
const ButtonComponent = styled.button`
  // width: 93px;
  height: 46px;
  padding: 12px, 16px, 12px, 16px;
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-top: 30px;
  outline: none;
  // background: isactive? gray : #00874E;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 500;
  border: none;

`


const Unauth: React.FC<UnauthProps> = (light, theme) => {
  const { instance } = useMsal();
  const handleLogout = () => {
    instance.logoutRedirect();
  };
  const [teamRole, setTeamRole] = useState("");
  const [inputText, setInputText] = useState("");
  const [fetchRequestItemJSON, performFetchRequestItemJSON] = useApiAction(getSNJSON);
  const [fetchJSON, performFetchJSON] = useApiAction(getSNJSON);
  const [check, setCheck] = useState("");
  const [isactive, setIsactive] = useState(false);
  const [checkRequired, setCheckRequired] = useState(false);
  const setcheckpostcomplete = useSetCheckpostcomplete();
  const checkpostcomplete = useCheckpostcomplete();
  let getBackendData = "";

  let errorMessage = (
    light &&
    light["theme"] &&
    light["theme"]["error"] &&
    light["theme"]["error"]["response"] &&
    light["theme"]["error"]["response"]["data"] &&
    light["theme"]["error"]["response"]["data"]["error"] &&
    light["theme"]["error"]["response"]["data"]["error"]["message"]
  );

  if (errorMessage) {
    getBackendData = JSON.parse(errorMessage);
  }
      
  function handleInputText(e){
    setInputText(e.target.value)
  }

  function DropdownValue(e) {
    setTeamRole(e.target.innerHTML);
  }

  function submitRequest(){
    let payload = {"text": inputText, "role": teamRole}
    let Field = ["Post", "urlRequestRole", "", payload ]
    if(teamRole && isactive === false){
      performFetchRequestItemJSON(Field)
      setIsactive(true)
      setCheckRequired(false)
    }
    else{
      setCheckRequired(true)
    }
  }
  
  useEffect(()=>{
    if(fetchRequestItemJSON && fetchRequestItemJSON.data){
      setCheck(fetchRequestItemJSON.data)
      setcheckpostcomplete(!checkpostcomplete)
      setTeamRole("")
      setInputText("")
      setIsactive(false)
    }
  }, [fetchRequestItemJSON, check])


  return (
    <UnauthPage>
      <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <ImageInfo style={{ padding:  getBackendData && getBackendData["User_submitted"]===false? "40px" : "20px" }}>
          {/* <img src={lock} className={"image_size"} alt="Lock" />
              <Text>
                  <i>
                      {light && light["theme"]
                      ? light["theme"] &&
                          light["theme"]["error"] &&
                          light["theme"]["error"]["response"] &&
                          light["theme"]["error"]["response"]["data"] &&
                          light["theme"]["error"]["response"]["data"]["error"] &&
                          light["theme"]["error"]["response"]["data"]["error"]["message"]
                      : ""}
                  </i>
              </Text>
              <HomePage onClick={() => handleLogout()}>Back to Home</HomePage> */}
          {getBackendData && getBackendData["User_submitted"]===false?
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                width: "fit-content",
                height: "280px",
                marginLeft: "15px"
              }}
            >
              <Dropdown style={{ width: "300px", display: "flex" }}>
                <Dropdown.Toggle
                  variant=""
                  id="dropdown-basic"
                  style={{
                    width: "340px",
                    height: "48px",
                    textAlign: "left",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: checkRequired && isactive ===false? "1px solid red" : "1px solid rgba(0, 0, 0, 0.32)",
                  }}
                >
                  {teamRole ? teamRole : "Request a role"}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ height: "200px", overflowY: "scroll" }}>
                  {getBackendData &&
                    getBackendData["role"].map((e, index) => {
                      return (
                        <>
                          <Dropdown.Item
                            onClick={DropdownValue}
                            style={{ width: "280px" }}
                            key={index}
                          >
                            {e}
                          </Dropdown.Item>
                        </>
                      );
                    })}
                </Dropdown.Menu>
              </Dropdown>
              <textarea
                placeholder="Please state the reason for your request"
                style={{
                  height: "127px",
                  width: "300px",
                  border: "1px solid rgba(0, 0, 0, 0.32)",
                  marginTop: "20px",
                  resize: "none",
                  borderRadius: "4px",
                  outline: "none",
                  padding: "9px",
                }}
                onChange={(e)=>handleInputText(e)}
                value={inputText}
              >
              </textarea>
              <ButtonComponent
                style={{ background: isactive? "gray" : "#00874E", width: isactive? "140px" : "93px"  }}
                onClick={submitRequest}
              >
                {isactive? "Please wait..." : "Submit"}
              </ButtonComponent>
            </div>
            : 
            <div>
              <div>
                <div style={{ width: "fit-content", margin: "auto"}}>
                  <RequestSubmitIcon scale={7} altText="Request Submit"/>
                </div>
              </div>
              <Message>
                {getBackendData && getBackendData["message"]}
              </Message>
            </div>
          }
        </ImageInfo>
      </div>
    </UnauthPage>
  );
};

export default Unauth;