import styled from "styled-components";

export const ThemebuttonNight = styled.button`
display: flex;
width: 50%;
height: 100%;
background:${({theme})=>theme.themebuttonlight};
color:${({theme})=>theme.mainFont};
border-radius: ${({theme})=>theme.darkborder};
border:none;

@media(max-width:800px) {
  width: 50%;
}
`;
export const ThemebuttonDay = styled.button`
display: flex;
width: 50%;
height: 100%;
padding:5px 10px 28px 40px;
background:${({theme})=>theme.themebuttondark};
color:${({theme})=>theme.mainFont};
border-radius: ${({theme})=>theme.lightborder};
border:none;

@media(max-width:800px) {
  width: 50%;
  padding:5px 10px 28px 30px;
}
`;