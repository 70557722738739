import React from 'react';

import { IconWrapper } from '../styled-components';

// import { ReactComponent as User } from './user-icon.svg';
import { ReactComponent as User } from './menu_icon_night.svg';
import { ReactComponent as Users } from './menu_icon.svg';

type UserIconProps = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale?: number;
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: string;
  theme:any;
};

const UserIcon: React.FC<UserIconProps> = ({ scale = 1, altText, theme }) => {
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText} style={{ marginBottom: "10px"}}>
      {theme === "light" ? <Users/> : <User/>}
    </IconWrapper>
  );
};

export default UserIcon;