import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Speedometer from '../speedometer/speedometer';
import TableTapComponent from '../table/TableTab';
import CardComponent from '../card/Card';
import Kinderegg from '../kinderegg/Kinderegg';
import FinanceSummary from '../finance-summery-barchart';
import Graph from '../graph';


type WrapperProps={
    json: any;
    noSpace: boolean;
    theme: any;
    device: any;
    fromWrapper: boolean;
}


const Wrapper: React.FC<WrapperProps> = ({json, noSpace = false, device, theme, fromWrapper}) => {   
    
    function EstimateColWidth(width){
        let colSize =width/8.33
        var intvalue = Math.floor(colSize); 
        return(intvalue)
    }
    
    
    return (
        
        <Row style={{marginTop: '2%'}}>
            {json['WrapperElement'].map((wElement:any, windex: any) => {
                switch(wElement.ElementType) {
                    case "SpeedometerSection":
                        return( 
                                <Col lg={EstimateColWidth(wElement["SpaceAllocation"])}>
                                    <div style={{padding: "15px", backgroundColor: theme === "light" ? "white" : "#17191D", borderRadius: '45px'}}>
                                        <Speedometer json={wElement} key={windex} noSpace={false} device={device} fromWrapper={true} theme={theme}/>
                                    </div>
                                </Col>
                            )
                            
                    case "TableSection":
                        return (
                            <Col lg={EstimateColWidth(wElement["SpaceAllocation"])}>
                                    <div style={{padding: "15px", backgroundColor: theme === "light" ? "white" : "#17191D", borderRadius: '45px'}}>
                                        <TableTapComponent theme={theme} json={wElement} key={windex} fromWrapper={true}/>
                                    </div>
                                </Col>
                        )
                    case "CardSection":
                        return (
                            <Col lg={EstimateColWidth(wElement["SpaceAllocation"])}>
                                <div style={{ padding: "10px", backgroundColor: theme === "light" ? "white" : "#17191D",borderRadius: '45px', margin: 'auto'}}>
                                    <CardComponent json={wElement} key={windex} fromWrapper ={true}/>
                                </div>
                            </Col>
                        )
                    case "GraphSection":
                        return (
                            <Col lg={EstimateColWidth(wElement["SpaceAllocation"])}>
                                <div style={{ padding: "15px", backgroundColor: theme === "light" ? "white" : "#17191D", borderRadius: '45px'}}>
                                        <Graph json={wElement} key={windex} fromKinderegg={false} theme={theme}/>
                                </div>
                            </Col>
                        )
                }
            })}
        </Row>
    )
}


export default Wrapper;