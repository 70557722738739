// @ts-nocheck

import React, { useRef, useEffect, useState } from "react";
import {
  StatusWrapper
} from "./styled-components";
import ContentContainer from "../content-container";
import {withTheme} from "styled-components";
import {defaults} from 'react-chartjs-2';
import {Bar} from "react-chartjs-2";

type QualysBarChartProps = {
  barData: any,
  getdata: any,
  getmaintainAspectRatio: boolean,
  theme: {
    light: boolean,
  },
  axiscolour:any,
  limitHeight: boolean
};

// todo: Update getData to more relevant name
function get_option(getdata, getmaintainAspectRatio){
  
  return {
    borderRadius: 5,
      plugins: {
          legend: {
            display: getdata['Data']['legend']['display'],
            position: getdata['Data']['legend']['position'],
            align: getdata['Data']['legend']['alignment'],
            labels: {
              boxHeight: 1
            }
          },
          title: {
              display: true,
              text: getdata['Data']['Heading']
          }
      },
      scales: {
        y: {
          suggestedMin: 0,
          ticks: {
            beginAtZero: true,
            precision: 0,
					},
          grid: {
            drawBorder: false,
            display: false
          }
        },
        x: {
          grid: {
            drawBorder: false,
            display: false
            }
          },
      },
      indexAxis: getdata['Data']['indexAxis'],
      maintainAspectRatio: getmaintainAspectRatio
  }
}

const QualysBarChart: React.FC<QualysBarChartProps> = ({ barData, getdata, getmaintainAspectRatio, theme,axiscolour, limitHeight}) => {
  const inputRef = useRef(null);
  const [options, setOptions] = useState(get_option(getdata, getmaintainAspectRatio));
  defaults.color = theme.light ? "#000000" : "#FFFFFF";
  
  const annotation1 = []
  
  useEffect(() => {
    if (inputRef && inputRef['current'] && inputRef['current']['scales'] && (getdata['Data']['indexAxis'] == 'x')){
      let y_axis = inputRef['current']['scales']['y']['ticks']
      let min = 0 
      let max = 0
      y_axis.map((item, index) => {
        if (index%2 == 0){
          if (index != y_axis.length-1){
            let diff = y_axis[index+1]['value'] - y_axis[index]['value'] 
            min = y_axis[index]['value'] + (diff/2)
            max = y_axis[index+1]['value'] + (diff/2)
            annotation1.push({
              type: 'box',
              drawTime: 'beforeDraw',
              yMin: min,
              yMax: max,
              borderColor: axiscolour?'rgb(242, 244, 248, 0.9)':"#1F2125",
              borderWidth: 1,
              backgroundColor: axiscolour?"#F2F4F8":"#1F2125",
            })
          }
        }
      })
    }   
    setOptions(prev => ({...prev,scales: {
      y: {
        ticks: {
          color:axiscolour?"black":"#B8B9BD"
        },
        grid: {
        drawBorder: false,
        display: false
      }
      },
      x: {
        ticks: {
          color:axiscolour?"black":"#B8B9BD"
        },
        grid: {
          drawBorder: false,
          display: false
        }
      },
    },
    plugins: 
    {
      legend: {
                display: getdata['Data']['datasets'][0]['label']===""?false:getdata['Data']['legend']['display'],
                position: getdata['Data']['legend']['position'] != ''? getdata['Data']['legend']['position']: "top",
                 align: getdata['Data']['legend']['alignment'] != ''?getdata['Data']['legend']['alignment']:"center",
              },
      annotation:{
        annotations: annotation1
      }}})) 
  }, [inputRef,axiscolour ])
  

  
  return (
    <StatusWrapper height={limitHeight? limitHeight:0}> 
      <ContentContainer className="bar-chart-section">
        {/*@ts-ignore*/}
        <Bar ref={inputRef} type={"bar"} data={barData} options={options}  />
      </ContentContainer>
    </StatusWrapper>
  );
};

export default withTheme(QualysBarChart);