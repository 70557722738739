import React from "react";

import { IconWrapper } from "../styled-components";

import { ReactComponent as drammenKommune } from "./Drammenkomm.svg";
import styled from "styled-components";

const StyleddrammenKommuneIcon = styled(drammenKommune)``;
type drammenKommuneIconProps = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale?: number;
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText?: string;
};

const DrammenKommuneIcon: React.FC<drammenKommuneIconProps> = ({
  scale,
  altText,
}) => {
  return (
    <IconWrapper width={2 * scale} aria-label={altText}>
      <StyleddrammenKommuneIcon />
      {/* <img src={drammenKommune} width="130" height="150" /> */}
    </IconWrapper>
  );
};

export default DrammenKommuneIcon;
